import React, { useState } from "react";
import { TbArrowsSort } from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
import { setting } from "../../Asset/Asset";

const sorts = [
  {
    id: 1,
    name: "Popularity",
    value: "popularity",
  },

  {
    id: 2,
    name: "Delivery Date",
    value: "date",
  },

  {
    id: 3,
    name: "Nearest Location",
    value: "location",
  },

  {
    id: 4,
    name: "Price: High to Low",
    value: "high",
  },

  {
    id: 5,
    name: "Price: Low to High",
    value: "low",
  },
];

const Header = ({
  show,
  setShow,
  toggleCategory,
  setToggleCategory,
  setShowFilter,
  setShowCuisinePopup,
  setShowPreOrderPopup,
  setShowStartDayPopup,
}) => {
  const [sortValue, setSortValue] = useState("Popularity");

  const handleChangeOptions = (e, value) => {
    const { checked } = e.target;
    if (checked) {
      setSortValue(value);
    }
  };

  return (
    <div className="mt-10 w-11/12 xl:w-3/4 mx-auto h-full grid grid-cols-12 gap-x-2 relative">
      {/* category toggle */}
      <div
        className="col-span-3 2xl:col-span-2 w-fit 2xl:w-full h-12 mt-1 rounded-full flex items-center gap-x-5 py-1 px-2"
        style={{ background: "#ECEDEE" }}
      >
        <div
          className={`${
            !toggleCategory ? "bg-white px-4 font-medium" : "pl-4"
          } py-1.5 rounded-full text-sm cursor-pointer`}
          onClick={() => setToggleCategory(false)}
        >
          <p>Delivery</p>
        </div>
        <div
          className={`${
            toggleCategory ? "bg-white px-4 font-medium" : "px-2"
          } py-1.5 rounded-full text-sm cursor-pointer`}
          onClick={() => setToggleCategory(true)}
        >
          <p>Pickup</p>
        </div>
      </div>

      {/* middle section */}
      {/* <div className="col-start-3 col-end-11 w-full h-full 2xl:flex flex-wrap items-center hidden gap-x-5 px-5 mt-0 ">
        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowFilter(true)}
        >
          <img src={setting} width={16} height={0} alt="setting" />
          <p className="text-sm text-secondary">Filter</p>
        </div>

        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowCuisinePopup(true)}
        >
          <p className="text-sm text-secondary">Cuisine</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>

        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowPreOrderPopup(true)}
        >
          <p className="text-sm text-secondary">Pre Order Type</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowStartDayPopup(true)}
        >
          <p className="text-sm text-secondary">Start Date</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
        <div className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit">
          <p className="text-sm text-secondary">End Date</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
      </div> */}

      {/* sorting */}
      {/* <div className="col-start-10 2xl:col-start-12 col-end-13 w-full h-full ">
        <div className="flex items-center justify-end gap-x-2">
          <TbArrowsSort />
          <p className="text-sm font-medium">Sort by:</p>
        </div>
        <div
          className="flex items-center gap-x-2 py-2 justify-end cursor-pointer"
          onClick={() => setShow(!show)}
        >
          <h6 className="capitalize text-primary font-medium">{sortValue}</h6>
          <IoIosArrowDown className="text-secondary" />
        </div>
      </div> */}
      {/* {show && (
        <div className="w-fit absolute px-5 right-0 border z-40 py-2 bg-white rounded-md top-16">
          {sorts.map((val) => {
            return (
              <div
                className="flex items-center gap-x-3 py-1.5 cursor-pointer hover:text-secondary"
                key={val.id}
                onClick={() => {
                  setSortValue(val.name);
                  setShow(false);
                }}
              >
                <input
                  type="radio"
                  name="radio"
                  className="bg-primary w-3.5 h-3.5"
                  checked={sortValue.includes(val.name) ? true : false}
                  onChange={(e) => handleChangeOptions(e, val.name)}
                />
                <p
                  className={`${
                    sortValue.includes(val.name) ? "font-medium" : ""
                  } `}
                >
                  {val.name}
                </p>
              </div>
            );
          })}
        </div>
      )} */}

      {/* <div className="col-span-12 w-full h-full 2xl:hidden mt-5 2xl:mt-0 flex flex-wrap items-center gap-x-5">
        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowFilter(true)}
        >
          <img src={setting} width={16} height={0} alt="setting" />
          <p className="text-sm text-secondary">Filter</p>
        </div>

        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowCuisinePopup(true)}
        >
          <p className="text-sm text-secondary">Cuisine</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>

        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowPreOrderPopup(true)}
        >
          <p className="text-sm text-secondary">Pre Order Type</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
        <div
          className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit"
          onClick={() => setShowStartDayPopup(true)}
        >
          <p className="text-sm text-secondary">Start Date</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
        <div className="flex items-center gap-x-2 p-3 border rounded-md cursor-pointer w-fit">
          <p className="text-sm text-secondary">End Date</p>
          <IoIosArrowDown className="text-secondary text-sm" />
        </div>
      </div> */}
    </div>
  );
};

export default Header;
