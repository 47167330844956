import Cookies from "js-cookie";
import moment from "moment";
import React from "react";
import { AiFillClockCircle, AiFillHeart, AiFillStar } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  fetchAddRestaurantWishlist,
  fetchDeleteRestaurantWishlist,
} from "../../Redux/Wishlist/action";
import { useNavigate } from "react-router-dom";

const RestaurantCard = ({ data, handleNavigate, wishlisted }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const handelClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddRestaurantWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeleteRestaurantWishlist(id, token));
  };

  return (
    <div
      className="cursor-pointer hover:shadow-md rounded-xl transition-all duration-500 ease-in-out p-1 hover:border hover:p-3"
      onClick={() => handleNavigate(data.id)}
    >
      <div className="w-full h-60 overflow-hidden rounded-xl relative">
        <img
          src={data.imageUrl}
          width={2000}
          height={0}
          alt="restaurant img"
          className=" object-cover w-full h-full transition-all duration-500 ease-in-out hover:scale-125"
        />
        {/* <div className="w-fit px-3 py-1 top-3 left-0 text-sm absolute rounded-r-full bg-red-600 text-white">
          {data.tag}
        </div> */}
        {wishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickRemove(e, data.id)}
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : data.isWishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickRemove(e, data.id)}
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handelClickAdd(e, data.id)}
          >
            <BsHeart />
          </div>
        )}
        {/* <p className="py-1 px-3 w-fit text-sm rounded-md font-medium text-primary bg-white absolute bottom-3 right-3">
          {data.distance}
        </p> */}
      </div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <h5 className="w-3/4 truncate font-medium">{data.restaurantName}</h5>
          <div className="text-sm py-1 px-2 bg-primary text-white rounded-md w-fit flex items-center gap-x-2">
            <AiFillStar className="fill-white" />
            <p>{data.averageRating}</p>
          </div>
        </div>
        <div className="flex items-center gap-x-2 w-full text-sm mt-2">
          <MdLocationOn className="text-lg text-primary" />
          <p className=" text-gray-400 truncate w-10/12">{data.location}</p>
        </div>
        <hr className="w-full mt-4" />
        <div className="flex items-center gap-x-4 mt-3">
          <div
            className={`flex items-center gap-x-2 ${
              data.isOpen ? "text-primary" : "text-red-600"
            } text-sm`}
          >
            <AiFillClockCircle />
            <p className="font-medium">{data.isOpen ? "Open" : "Close"} </p>
          </div>
          <p className="text-sm font-medium">
            {moment.utc(data.openingTime, "HH:mm:ss").local().format("LT")} -{" "}
            {moment.utc(data.closingTime, "HH:mm:ss").local().format("LT")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RestaurantCard;
