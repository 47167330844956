import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TbArrowsSort } from "react-icons/tb";

const Header = () => {
  return (
    <div className="flex w-full justify-between">
      <h5 className="text-xl font-semibold">My Reviews</h5>
      {/* sorting */}
      {/* <div className=" w-fit h-full flex items-center gap-x-2 ">
        <div className="flex items-center justify-end gap-x-2">
          <TbArrowsSort />
          <p className="text-sm font-medium">Sort by:</p>
        </div>
        <div className="flex items-center gap-x-1 justify-end cursor-pointer">
          <h6 className="capitalize text-primary font-medium text-sm">
            Recent
          </h6>
          <IoIosArrowDown className="text-secondary text-xs" />
        </div>
      </div> */}
    </div>
  );
};

export default Header;
