import Cookies from "js-cookie";
import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  fetchAddCampaignWishlist,
  fetchDeleteCampaignWishlist,
} from "../../Redux/Wishlist/action";

const CampaignCarousel = ({ data }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 699,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddCampaignWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeleteCampaignWishlist(id, token));
  };

  return (
    <Slider {...settings}>
      {data.map((data) => {
        return (
          <div
            key={data.id}
            className="cursor-pointer hover:shadow-md rounded-xl transition-all duration-500 ease-in-out p-4 hover:border hover:p-4"
            onClick={() => navigate(`/campaign/${data.id}`)}
          >
            <div className="w-full h-60 md:h-52 2xl:h-60 overflow-hidden rounded-xl relative">
              <img
                src={data.campaignImage}
                width={2000}
                height={0}
                alt="campaign img"
                className=" object-cover w-full h-full transition-all duration-500 ease-in-out hover:scale-125"
              />
              {data.isWishlisted ? (
                <div
                  className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
                  onClick={(e) => handleClickRemove(e, data.id)}
                >
                  <AiFillHeart className="fill-red-600 text-xl" />
                </div>
              ) : (
                <div
                  className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
                  onClick={(e) => handleClickAdd(e, data.id)}
                >
                  <BsHeart />
                </div>
              )}
            </div>
            <h6 className="font-medium mt-5"> {data.campaignName}</h6>
          </div>
        );
      })}
    </Slider>
  );
};

export default CampaignCarousel;
