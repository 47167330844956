import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  orders: "",
  message: "",
  orderId: "",
  errMsg: "",
  data: {},
  coinData: "",
  coinMsg: "",
  coinErrMsg: "",
  cMsg: "",
  cErrMsg: "",
  pMsg: "",
  pErrMsg: "",
  stripeMsg: "",
  stripeErr: "",
  stripe: "",
};

export const Checkout = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RESTAURANT_CART:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload,
      };

    case ActionTypes.CONFIRM_CART_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload,
        cMsg: "Successfull",
      };

    case ActionTypes.CONFIRM_CART_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        cErrMsg: action.payload.message,
      };

    case ActionTypes.CART_CHECKOUT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        orderId: action.payload.cutomerOrderId,
      };

    case ActionTypes.CART_CHECKOUT_FAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        errMsg: action.payload?.message,
      };

    case ActionTypes.VALIDATE_PREORDER_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        data: action.payload,
        message: "validate successfully",
      };

    case ActionTypes.VALIDATE_PREORDER_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.PREORDER_CHECKOUT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        pMsg: action.payload.message,
        orderId: action.payload.orderId,
      };

    case ActionTypes.PREORDER_CHECKOUT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        pErrMsg: action.payload.message,
      };

    case ActionTypes.CAMPAIN_CHECKOUT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        orderId: action.payload.orderId,
      };

    case ActionTypes.CAMPAIGN_CHECKOUT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CAMPAIGN_STRIPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        stripe: action.payload,
        stripeMsg: "successfull",
      };

    case ActionTypes.CAMPAIGN_STRIPE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CART_STRIPE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        stripe: action.payload,
        stripeMsg: "Successfull",
      };

    case ActionTypes.CART_STRIPE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.USE_COIN_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        coinMsg: "Coin used successfully",
        coinData: action.payload.newCalcData,
      };

    case ActionTypes.USE_COIN_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        coinErrMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_CHECKOUT_DATA:
      return {
        isloading: true,
        success: false,
        orders: "",
        message: "",
        orderId: "",
        errMsg: "",
        data: {},
        pMsg: "",
        pErrMsg: "",
        stripe: "",
        stripeMsg: "",
        stripeErr: "",
        cMsg: "",
        cErrMsg: "",
        coinData: "",
        coinMsg: "",
        coinErrMsg: "",
      };

    default:
      return state;
  }
};
