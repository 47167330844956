import { Form, Formik } from "formik";
import React from "react";
import { IoMdClose } from "react-icons/io";
import * as Yup from "yup";
import FormikControl from "../Formik/FormikControl";
import { useState } from "react";
import Button from "../Button/Button";
import { khaltiKey } from "../../Shared/BaseUrl";
import { useDispatch } from "react-redux";
import { fetchInitiateTransaction } from "../../Redux/Khalti/action";
import Cookies from "js-cookie";

const Khalti = ({ setShowKhaltiPopup, price, id, name, setPin }) => {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const initialValues = {
    mobile: "",
    transaction_pin: "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long"),
    transaction_pin: Yup.string().required("Required*"),
  });

  const onSubmit = (values, onSubmitProps) => {
    setPin(values.transaction_pin);
    const body = {
      public_key: khaltiKey,
      mobile: values.mobile,
      transaction_pin: values.transaction_pin,
      amount: price.toFixed(2) * 100,
      product_identity: id,
      product_name: name,
      product_url: window.location.href,
    };
    dispatch(fetchInitiateTransaction(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  return (
    <div className="absolute top-40 w-11/12 md:w-2/3 lg:w-2/5 xl:w-1/3 left-[4%] md:left-[16%] lg:left-1/4 xl:left-1/3 rounded-xl border bg-white z-40 h-fit">
      <div className="p-5 w-full flex items-center justify-between">
        <h6 className="font-semibold text-lg">Khalti Payment </h6>
        <div
          className="p-2 flex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => setShowKhaltiPopup(false)}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>
      <hr className="w-full" />

      <div className="w-full p-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full">
                <FormikControl
                  label="Mobile Number"
                  control="input"
                  name="mobile"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="w-full mt-7">
                <FormikControl
                  label="Transaction Pin"
                  name="transaction_pin"
                  placeholder="Your Transaction Pin"
                  control="password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>

              <Button
                value="Get OTP"
                className="bg-primary text-white w-full rounded-xl mt-7 py-3"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Khalti;
