import React from "react";
import { GoDotFill } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";

const CampaignOrderCard = ({ data }) => {
  return (
    <Link to={`/me/campaign-orders/${data.id}`}>
      <div className="border w-full rounded-xl grid grid-cols-12 gap-x-5 p-5 hover:shadow-md">
        <div className="col-span-12 md:col-span-3 w-full h-44 md:h-32  rounded-xl overflow-hidden">
          <img
            src={data.campaignData.campaignImage}
            className="w-full h-full object-fill"
          />
        </div>

        <div className="col-start-1 md:col-start-4 col-end-13 w-full mt-5 md:mt-0 md:px-5">
          <div className="flex flex-wrap justify-between items-center w-full">
            <div className="flex items-center gap-x-2">
              <h6 className="font-semibold text-base md:text-lg">
                {data.campaignName}
              </h6>
              <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium">
                {data?.campaignData?.itemData?.length} Items
              </p>
            </div>
            <p className="text-xs md:text-sm text-secondary">{data.time}</p>
          </div>
          {/* total */}
          <div className="flex items-center gap-x-2 mt-2">
            <p className="text-secondary text-sm md:text-base">Grand Total:</p>
            <h6 className="font-semibold text-sm md:text-base">
              Rs.{data.campaignPrice}
            </h6>
          </div>

          <hr className="w-full mt-6" />

          <div className="mt-4 flex items-center justify-between flex-wrap">
            <div className="flex items-center gap-x-2">
              <p className="text-secondary text-sm">Order Status:</p>
              <div
                className={`${
                  data.completionStatus === "APPROVED"
                    ? "text-green-500"
                    : data.completionStatus === "COMPLETED"
                    ? "text-primary"
                    : "text-orange-500"
                } flex items-center gap-x-1`}
              >
                <GoDotFill />
                <p className="font-medium text-sm">{data.completionStatus}</p>
              </div>
            </div>

            <div className="w-fit border py-2.5 px-3 rounded-lg flex items-center gap-x-2 cursor-pointer mt-5 sm:mt-0">
              <AiOutlineEye className="text-primary text-lg" />
              <p className="text-sm">View Detail</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CampaignOrderCard;
