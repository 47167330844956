import React, { useEffect, useState } from "react";
import Header from "./Header";
import {
  Breadcrum,
  Load,
  Pagination,
  PreOrderCard,
  SinglePagnination,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import Filter from "./Filter";
import CuisinePopup from "./Popup/CuisinePopup";
import PreOrderPopup from "./Popup/PreOrderPopup";
import StartDayPopup from "./Popup/StartDayPopup";
import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
  removePreorderData,
} from "../../Redux/Preorder/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { removeWishlistData } from "../../Redux/Wishlist/action";

const PreOrder = () => {
  const [page, setPage] = useState(1);
  const [toggleCategory, setToggleCategory] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showCuisinePopup, setShowCuisinePopup] = useState(false);
  const [showPreOrderPopup, setShowPreOrderPopup] = useState(false);
  const [showStartDayPopup, setShowStartDayPopup] = useState(false);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const location = localStorage.getItem("GCity");
  const street = localStorage.getItem("GStreet");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );
  const preOrder = useSelector(
    (state) => state.preOrder.preOrders,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.preOrder.totalData,
    shallowEqual
  );

  const totalPage = useSelector(
    (state) => state.preOrder.totalPage,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  useEffect(() => {
    toggleCategory
      ? preOrder &&
        setData(
          preOrder &&
            preOrder?.filter(
              (val) => val.orderType === "PICKUP" || val.orderType === "BOTH"
            )
        )
      : preOrder &&
        setData(
          preOrder &&
            preOrder?.filter(
              (val) => val.orderType === "DELIVERY" || val.orderType === "BOTH"
            )
        );
  }, [preOrder, toggleCategory]);

  useEffect(() => {
    scrollToTop();
    token
      ? lat &&
        lng &&
        dispatch(fetchGetActivePreorderUser(page, lat, lng, token))
      : lat && lng && dispatch(fetchGetActivePreorder(page, lat, lng));
    return () => {
      dispatch(removePreorderData());
    };
  }, [page, lat, lng, token]);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg &&
      lat &&
      lng &&
      dispatch(fetchGetActivePreorderUser(page, lat, lng, token));
    setTimeout(() => {
      wishlistMsg && dispatch(removeWishlistData());
    }, 100);
  }, [wishlistMsg]);

  if (loading || !preOrder) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="bg-white py-16 md:py-24 relative">
      <Breadcrum location={street ? street : location} title="Preorder" />
      <Header
        show={showSortOptions}
        setShow={setShowSortOptions}
        toggleCategory={toggleCategory}
        setToggleCategory={setToggleCategory}
        setShowFilter={setShowFilter}
        setShowCuisinePopup={setShowCuisinePopup}
        setShowPreOrderPopup={setShowPreOrderPopup}
        setShowStartDayPopup={setShowStartDayPopup}
      />
      <hr className="w-full mt-5" />

      {showFilter && <Filter setShowFilter={setShowFilter} />}
      {showCuisinePopup && (
        <CuisinePopup setShowCuisinePopup={setShowCuisinePopup} />
      )}
      {showPreOrderPopup && (
        <PreOrderPopup setShowPreOrderPopup={setShowPreOrderPopup} />
      )}

      {showStartDayPopup && (
        <StartDayPopup setShowStartDayPopup={setShowStartDayPopup} />
      )}

      {showFilter ||
      showCuisinePopup ||
      showPreOrderPopup ||
      showStartDayPopup ? (
        <div
          className="w-full absolute top-0 h-full bg-black opacity-10"
          onClick={() => {
            setShowFilter(false);
            setShowCuisinePopup(false);
            setShowPreOrderPopup(false);
            setShowStartDayPopup(false);
          }}
        ></div>
      ) : null}

      <div className="w-11/12 xl:w-3/4 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
        {data &&
          data.map((data) => {
            return (
              <div key={data.planId} className="w-full mt-10">
                <PreOrderCard data={data} />
              </div>
            );
          })}
      </div>

      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>

      {showSortOptions && (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => setShowSortOptions(false)}
        ></div>
      )}
    </div>
  );
};

export default PreOrder;
