import moment from "moment";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";

const PreOrderHistoryCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="border w-full rounded-xl grid grid-cols-12 gap-x-5 p-5 hover:shadow-md">
      <div className="col-span-12 md:col-span-3 w-full h-44 md:h-32 rounded-xl overflow-hidden">
        <img
          src={data.planImage}
          alt="img"
          className="w-full h-full object-fill"
        />
      </div>

      <div className="col-start-1 md:col-start-4 col-end-13 w-full mt-5 md:mt-0">
        <div className="flex justify-between items-center w-full flex-wrap md:flex-nowrap">
          <div className="flex items-center gap-x-2">
            <h6 className="font-semibold text-base md:text-lg">
              {data.preOrderPlanName}
            </h6>
            {data.isTakeAway ? (
              <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium">
                Pickup
              </p>
            ) : (
              <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium">
                Delivery
              </p>
            )}
          </div>
          <p className="text-xs md:text-sm text-secondary">
            {moment.utc(data.orderPlacedDateTime).local().fromNow()}
          </p>
        </div>
        {/* total */}
        <div className="flex items-center gap-x-2 mt-2">
          <p className="text-secondary text-sm md:text-base">Total:</p>
          <h6 className="font-semibold text-sm md:text-base">
            Rs.{data.paidAmount}
          </h6>
        </div>

        <hr className="w-full mt-6" />

        <div className="mt-4 flex flex-wrap 2xl:flex-nowrap justify-between w-full">
          <div className="flex items-center gap-x-1">
            <p className="text-secondary text-xs">Order Status:</p>
            <div
              className={`${
                data.orderState === "REQUESTED"
                  ? "text-primary"
                  : data.orderState === "APPROVED"
                  ? "text-green-600"
                  : data.orderState === "DECLINED"
                  ? "text-red-600"
                  : "text-primary"
              } font-medium text-xs flex gap-x-1 items-center`}
            >
              <GoDotFill className="text-xs mt-0.5" />
              <p>{data.orderState}</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 flex-wrap mt-5 2xl:mt-0">
            <div
              className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-2 cursor-pointer"
              onClick={() => navigate(`/me/pre-orders/${data.preOrderId}`)}
            >
              <AiOutlineEye className="text-primary text-base" />
              <p className="text-xs">View Detail</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreOrderHistoryCard;
