import React from "react";
import { IoMdClose } from "react-icons/io";
import {
  biratnagar,
  currentLocation,
  kathmandu,
  searchLocation,
} from "../../Asset/Asset";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
} from "../../Redux/Preorder/action";
import {
  fetchGetNearbyRestaurant,
  fetchGetNearbyRestaurantUser,
  fetchGetPopularRestaurant,
  fetchGetPopularRestaurantUser,
} from "../../Redux/Restaurant/action";
import {
  fetchGetACtiveCampaignUser,
  fetchGetActiveCampaign,
} from "../../Redux/Campaign/action";
import { fetchGetAdvertisement } from "../../Redux/Advertisement/action";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const LocationPopup = ({ setShowLocation, setSearchLocation }) => {
  const [latitude, setLatitude] = useState(localStorage.getItem("GLat"));
  const [longitude, setLongitude] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));

  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const keyword = "";
  const token = Cookies.get("access_token");

  const dispatch = useDispatch();

  const handleClickSetCurrentLocation = () => {
    // Check if geolocation is available in the browser
    if (navigator.geolocation) {
      // Get the user's current position

      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        // Use a reverse geocoding API to get the city name from lat and lng

        const apiKey = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU";
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );
        const addressComponents = response.data.results[0]?.address_components;
        // Find the city in the address components (it might have different types)
        const cityComponent = addressComponents.find(
          (component) =>
            component.types.includes("sublocality_level_1") ||
            component.types.includes("sublocality") ||
            component.types.includes("political")
        );

        const codeComponent = addressComponents.find((component) =>
          component.types.includes("plus_code")
        );

        const districtComponent = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_2")
        );

        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );

        setCode(codeComponent?.long_name);
        setDistrict(districtComponent?.long_name);
        setCountry(countryComponent?.long_name);

        setCity(cityComponent?.long_name);
      });
    }

    setTimeout(() => {
      setShowLocation(false);
      localStorage.removeItem("GStreet");
    }, 1000);
  };

  const handleSetLocationKathmandu = async () => {
    setLatitude(Number(27.7172));
    setLongitude(Number(85.324));

    const apiKey = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU";
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Number(
        27.7172
      )},${Number(85.324)}&key=${apiKey}`
    );
    const addressComponents = response?.data?.results[0]?.address_components;
    // Find the city in the address components (it might have different types)
    const cityComponent = addressComponents.find(
      (component) =>
        component.types.includes("sublocality_level_1") ||
        component.types.includes("sublocality") ||
        component.types.includes("political")
    );

    const codeComponent = addressComponents.find((component) =>
      component.types.includes("plus_code")
    );

    const districtComponent = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_2")
    );

    const countryComponent = addressComponents.find((component) =>
      component.types.includes("country")
    );

    setCode(codeComponent?.long_name);
    setDistrict(districtComponent?.long_name);
    setCountry(countryComponent?.long_name);

    setCity(cityComponent?.long_name);

    setTimeout(() => {
      setShowLocation(false);
      localStorage.removeItem("GStreet");
    }, 1000);
  };

  const handleSetLocationBiratnagar = async () => {
    setLatitude(Number(26.4525));
    setLongitude(Number(87.2718));

    const apiKey = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU";
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Number(
        26.4525
      )},${Number(87.2718)}&key=${apiKey}`
    );
    const addressComponents = response.data.results[0]?.address_components;
    // Find the city in the address components (it might have different types)
    const cityComponent = addressComponents.find(
      (component) =>
        component.types.includes("sublocality_level_1") ||
        component.types.includes("sublocality") ||
        component.types.includes("political")
    );

    const codeComponent = addressComponents.find((component) =>
      component.types.includes("plus_code")
    );

    const districtComponent = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_2")
    );

    const countryComponent = addressComponents.find((component) =>
      component.types.includes("country")
    );

    setCode(codeComponent?.long_name);
    setDistrict(districtComponent?.long_name);
    setCountry(countryComponent?.long_name);

    setCity(cityComponent?.long_name);

    setTimeout(() => {
      setShowLocation(false);
      localStorage.removeItem("GStreet");
    }, 1000);
  };

  useEffect(() => {
    localStorage.setItem("GLat", latitude);
    localStorage.setItem("GLng", longitude);
    localStorage.setItem("GCity", city);
    localStorage.setItem("GCode", code);
    localStorage.setItem("GDistrict", district);
    localStorage.setItem("GCountry", country);
  }, [longitude, latitude, city, code, district, country]);

  const handleCallAPi = () => {
    token
      ? dispatch(fetchGetActivePreorderUser(1, latitude, longitude, token))
      : dispatch(fetchGetActivePreorder(1, latitude, longitude));
    token
      ? dispatch(
          fetchGetPopularRestaurantUser(
            1,
            city,
            latitude,
            longitude,
            keyword,
            token
          )
        )
      : dispatch(
          fetchGetPopularRestaurant(1, city, latitude, longitude, keyword)
        );
    token
      ? dispatch(
          fetchGetNearbyRestaurantUser(
            1,
            city,
            latitude,
            longitude,
            keyword,
            token
          )
        )
      : dispatch(
          fetchGetNearbyRestaurant(1, city, latitude, longitude, keyword)
        );
  };

  useEffect(() => {
    handleCallAPi();
  }, [latitude, longitude, city]);

  useEffect(() => {
    token
      ? dispatch(fetchGetACtiveCampaignUser(1, token))
      : dispatch(fetchGetActiveCampaign(1));
    dispatch(fetchGetAdvertisement());
  }, []);

  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-[30rem] border overflow-y-auto bg-white shadow-md z-40 pb-5 left-4 md:left-[20%] lg:left-1/4 xl:left-1/3 rounded-xl absolute top-28 md:top-40">
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-xl font-semibold">Select Your Location</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setShowLocation(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>
        <p className="text-secondary px-5">
          Current Location:{" "}
          <span className="font-medium text-primary">
            {street ? street : city}
          </span>
        </p>
        <hr className="w-full mt-5" />
      </div>
      <div className="flex justify-between flex-wrap mt-10 px-5">
        <div
          className="w-fit hover:rounded-xl hover:shadow-md cursor-pointer"
          onClick={handleClickSetCurrentLocation}
        >
          <img
            src={currentLocation}
            alt="current location"
            className="object-fill w-32 h-32 md:w-44 md:h-40"
          />
          <p className="font-medium text-sm text-center py-2 mt-2">
            Current Location
          </p>
        </div>

        <div
          className="w-fit hover:rounded-xl hover:shadow-md cursor-pointer"
          onClick={() => {
            setSearchLocation(true);
            setShowLocation(false);
          }}
        >
          <img
            src={searchLocation}
            alt="search location"
            className="object-fill w-32 h-32 md:w-44 md:h-40"
          />
          <p className="font-medium text-sm text-center py-2 mt-2">
            Search Location
          </p>
        </div>

        <div
          className="w-fit hover:rounded-xl hover:shadow-md mt-10 cursor-pointer"
          onClick={handleSetLocationKathmandu}
        >
          <img
            src={kathmandu}
            alt="kathmandu"
            className="object-fill w-32 h-32 md:w-44 md:h-40"
          />
          <p className="font-medium text-sm text-center py-2 mt-2">Kathmandu</p>
        </div>

        <div
          className="w-fit hover:rounded-xl hover:shadow-md mt-10 cursor-pointer"
          onClick={handleSetLocationBiratnagar}
        >
          <img
            src={biratnagar}
            alt="biratnagar"
            className="object-fill w-32 h-32 md:w-44 md:h-40"
          />
          <p className="font-medium text-sm text-center py-2 mt-2">
            Biratnagar
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocationPopup;
