import React from "react";
import { MobileIcon, appstore, googleplay } from "../../Asset/Asset";

const Download = () => {
  return (
    <div className="w-full bg-primary mt-20 rounded-3xl border grid gap-x-5 grid-cols-12 md:h-[22rem]">
      <div className="col-span-12 md:col-span-6 lg:col-span-7 2xl:col-span-8 w-full h-full p-6 md:p-10">
        <h4 className="text-white text-xl md:text-3xl font-semibold">
          Download The Guru-G App?
        </h4>
        <hr className="w-20 border border-white mt-4" />
        <p className="text-white text-xs md:text-sm lg:text-base mt-7 tracking-wide">
          Download the Guru-G app today and order your food online to get the
          fastest delivery.
        </p>
        <div className="flex items-center gap-x-2 lg:gap-x-10 mt-6 lg:mt-10">
          <a href="https://play.google.com/store/apps/details?id=com.beccorestaurant&pli=1">
            <img
              src={googleplay}
              alt="play store"
              width={2000}
              height={0}
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12 cursor-pointer"
            />
          </a>
          <a href="https://apps.apple.com/au/app/gurug-food/id1585408458">
            <img
              src={appstore}
              alt="app store"
              width={2000}
              height={0}
              className="w-28 h-8 md:w-36 md:h-9 lg:w-40 lg:h-12  cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="hidden md:block col-start-7 lg:col-start-8 2xl:col-start-9 col-end-13 w-full h-full relative">
        <img
          src={MobileIcon}
          alt="mobile icon"
          height={0}
          className="h-[400px] w-[700px] ml-10 2xl:object-cover"
        />
      </div>
    </div>
  );
};

export default Download;
