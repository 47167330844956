import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  review: [],
  totalData: "",
  totalPage: "",
};

export const Review = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_REVIEW_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Review added Successfully",
      };

    case ActionTypes.ADD_REVIEW_FAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_REVIEW_BY_RESTAURANT:
      return {
        ...state,
        isloading: false,
        success: true,
        review: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.REMOVE_REVIEW_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        totalData: "",
        totalPage: "",
      };

    default:
      return state;
  }
};
