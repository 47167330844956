import moment from "moment/moment";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { time } from "../../../../Asset/Asset";

const TimeSlot = ({
  selectPickup,
  scheduleTime,
  setScheduleTime,
  scheduleDate,
  setScheduleDate,
  setIsSchedule,
}) => {
  const timeslot = useSelector(
    (state) => state.preOrder.timeslot,
    shallowEqual
  );

  const handleAddTimeSlot = (slotId, time, endTime) => {
    if (time >= 0 && time <= 9) {
      setScheduleTime({
        id: slotId,
        time: `0${time}`,
        endTime: endTime,
      });

      setIsSchedule(false);
    } else {
      setScheduleTime({
        id: slotId,
        time: time,
        endTime: endTime,
      });
      setIsSchedule(false);
    }
  };
  return (
    <div className="absolute top-40 w-11/12 md:w-2/3 lg:w-2/5 xl:w-1/3 left-[4%] md:left-[16%] lg:left-1/4 xl:left-1/3 rounded-xl border bg-white z-40 h-fit">
      <h6 className="text-base font-semibold p-5">
        {" "}
        {selectPickup ? "Schedule Pickup" : "Schedule Delivery"}{" "}
      </h6>

      <hr className="w-full mt-2" />

      <div className="p-5">
        <h6 className="font-medium">
          {selectPickup ? "Pickup Date" : "Delivery Date"}{" "}
          <span className="text-secondary text-sm">
            ({selectPickup ? "Select a pickup date" : "Select a delivery date"})
          </span>
        </h6>

        {timeslot && timeslot.length ? (
          <div className="flex items-center gap-x-5 w-fit mt-3 flex-wrap">
            {timeslot.map((val, i) => {
              return (
                <h5
                  key={i}
                  className={`font-medium border rounded-lg p-3 mt-3 text-sm cursor-pointer ${
                    scheduleDate === val.slotDate
                      ? "border-primary text-primary"
                      : ""
                  }`}
                  onClick={() => {
                    setScheduleDate(val.slotDate);
                    setScheduleTime({
                      id: "",
                      time: "",
                    });
                  }}
                >
                  {moment(val.slotDate).format("DD-MM-YYYY")}
                </h5>
              );
            })}
          </div>
        ) : (
          <p className="text-sm text-red-700 mt-2">
            No Time Slot available for this pre order
          </p>
        )}
      </div>

      <hr className="w-full mt-2" />

      <div className="w-full flex items-center gap-x-5 flex-wrap p-5">
        {timeslot
          .find((item) => item.slotDate === scheduleDate)
          ?.timeResponses.map((timeResponse) => (
            <div
              key={timeResponse?.preOrderTimeSlotId}
              className={`font-medium border rounded-lg p-3 mt-3 text-xs cursor-pointer ${
                scheduleTime.time === timeResponse?.startTime ||
                scheduleTime.time === `0${timeResponse?.startTime}`
                  ? "border-primary text-primary"
                  : ""
              }`}
              onClick={() =>
                handleAddTimeSlot(
                  timeResponse?.preOrderTimeSlotId,
                  timeResponse?.startTime,
                  timeResponse?.endTime
                )
              }
            >
              <p>Start Time: {timeResponse?.startTime}</p>
              <p>End Time: {timeResponse?.endTime}</p>
              <p>Total Slot: {timeResponse?.slotData?.totalSlot}</p>
              <p>Remaining Slot: {timeResponse?.slotData?.remainingSlot}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TimeSlot;
