import moment from "moment";
import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { schedule } from "../../Asset/Asset";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchAddPreorderWishlist,
  fetchDeletePreorderWishlist,
} from "../../Redux/Wishlist/action";

const PreOrderCarousel = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 699,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddPreorderWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeletePreorderWishlist(id, token));
  };

  return (
    <Slider {...settings}>
      {data.map((data) => {
        return (
          <div
            key={data.planId}
            className="cursor-pointer hover:shadow-md rounded-xl transition-all duration-500 ease-in-out px-4 p-1 hover:p-4 hover:border mt-5"
            onClick={() => navigate(`/preorder/${data.planId}`)}
          >
            <div className="w-full h-60 overflow-hidden rounded-xl relative">
              <img
                src={data.planImage}
                width={2000}
                height={0}
                alt="pre order img"
                className=" object-cover w-full h-full transition-all duration-500 ease-in-out hover:scale-125"
              />
              {data.isWishlisted ? (
                <div
                  className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
                  onClick={(e) => handleClickRemove(e, data.planId)}
                >
                  <AiFillHeart className="fill-red-600 text-xl" />
                </div>
              ) : (
                <div
                  className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
                  onClick={(e) => handleClickAdd(e, data.planId)}
                >
                  <BsHeart />
                </div>
              )}
              <p className="py-1 px-3 w-fit text-sm rounded-md font-medium text-primary bg-white absolute bottom-3 left-3">
                {data.preOrderType}
              </p>
            </div>
            <div className="mt-5">
              <h6 className="font-medium"> {data.planName}</h6>
              {data.restaurantLocation ? (
                <div className="flex items-center gap-x-2 w-full text-sm mt-2">
                  <MdLocationOn className="text-lg text-primary" />
                  <p className=" text-gray-400">{data.restaurantLocation}</p>
                </div>
              ) : null}

              <hr className="w-full mt-5" />
              <div className="flex items-center justify-between mt-5">
                <div>
                  <div className="flex items-center gap-x-3">
                    <img
                      src={schedule}
                      width={20}
                      height={0}
                      alt="schedule img"
                    />
                    <p className="text-sm">Start Date</p>
                  </div>
                  <p className="text-xs xl:text-sm py-2 mt-2">
                    {moment
                      .utc(data.preOrderStartDate)
                      .local()
                      .format("DD-MM-YYYY")}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-x-3">
                    <img
                      src={schedule}
                      width={20}
                      height={0}
                      alt="schedule img"
                    />
                    <p className="text-sm">End Date</p>
                  </div>
                  <p className="text-xs xl:text-sm py-2 mt-2">
                    {moment
                      .utc(data.preOrderEndDate)
                      .local()
                      .format("DD-MM-YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default PreOrderCarousel;
