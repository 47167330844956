export const CART_LOADING = "CART_LOADING";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";
export const GET_MY_CART = "GET_MY_CART";
export const UPDATE_CART_ITEM_SUCCESS = "UPDATE_CART_ITEM_SUCCESS";
export const UPDATE_CART_ITEM_FAIL = "UPDATE_CART_ITEM_FAIL";
export const DELETE_RESTAURANT_FROM_CART_SUCCESS =
  "DELETE_RESTAURANT_FROM_CART_SUCCESS";
export const DELETE_RESTAURANT_FROM_CART_FAIL =
  "DELETE_RESTAURANT_FROM_CART_FAIL";

export const DELETE_ITEM_FROM_CART_SUCCESS = "DELETE_ITEM_FROM_CART_SUCCESS";

export const DELETE_ITEM_FROM_CART_FAIL = "DELETE_ITEM_FROM_CART_FAIL";
export const REMOVE_CART_DATA = "REMOVE_CART_DATA";
