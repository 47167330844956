import { Form, Formik } from "formik";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, FormikControl } from "../../../../Component";
import { Logo } from "../../../../Asset/Asset";
import { scrollToTop } from "../../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchForgotPassword,
  removeAuthData,
} from "../../../../Redux/Auth/action";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const message = useSelector((state) => state.auth.forgetMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password is too short")
      .required("Required*"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      newPassword: values.password,
      conformPassword: values.confirmPassword,
    };
    dispatch(fetchForgotPassword(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setSuccess(true);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setSuccess(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    success && navigate("/auth");
    success && Cookies.remove("token");
  }, [success]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 md:w-3/4 lg:w-1/2 mx-auto h-full rounded-xl mt-14 bg-white py-8 px-10">
        <div className="flex justify-center items-center flex-col">
          <img src={Logo} width="200" height="200" alt="logo" />
          <h5 className="font-semibold text-3xl mt-10">Reset Password!</h5>
          <p className="text-secondary py-2 mt-2">
            Your new password must be different from your previously used one
          </p>
        </div>
        <div className="mt-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="w-full">
                  <FormikControl
                    label="Password"
                    control="password"
                    name="password"
                    placeholder="Your Password"
                    type={`${showPassword ? "text" : "password"}`}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                </div>
                <div className="w-full mt-7">
                  <FormikControl
                    label="Confirm Password"
                    control="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={`${showConfirmPassword ? "text" : "password"}`}
                    setShowPassword={setShowConfirmPassword}
                    showPassword={showConfirmPassword}
                  />
                </div>
                <Button
                  value="Reset Password"
                  className="w-full bg-primary text-white rounded-xl py-3 mt-10"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
