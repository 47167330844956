import React from "react";
import { shallowEqual, useSelector } from "react-redux";

const PickupStallPopup = ({
  pickupStall,
  setPickupStall,
  setShowPickupStallPopup,
}) => {
  const detail = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  const handleSelectLocation = (id, name, location, lat, lng) => {
    setPickupStall({
      id: id,
      name: name,
      location: location,
      lat: lat,
      lng: lng,
    });

    setShowPickupStallPopup(false);
  };
  return (
    <div className="absolute top-40 w-11/12 md:w-2/3 lg:w-2/5 xl:w-1/3 left-[4%] md:left-[16%] lg:left-1/4 xl:left-1/3 rounded-xl border bg-white z-40 p-5 h-fit">
      <h6 className="font-semibold text-lg">Pickup Stalls </h6>
      <hr className="w-full mt-4" />

      <div className="mt-3 flex flex-col items-center gap-x-8 flex-wrap md:flex-nowrap">
        {detail.takeAwayStallsList && detail.takeAwayStallsList.length
          ? detail.takeAwayStallsList.map((val, index) => {
              return (
                <div
                  className={`${
                    pickupStall.id === val.id
                      ? "border-primary text-primary"
                      : ""
                  } w-full flex items-center gap-x-10 mt-4 flex-wrap md:flex-nowrap border rounded-lg p-3 cursor-pointer`}
                  key={index}
                  onClick={() =>
                    handleSelectLocation(
                      val.id,
                      val.stallName,
                      val.stallLocation,
                      val.stallLatitude,
                      val.stallLongitude
                    )
                  }
                >
                  <p className="w-fit font-medium text-sm mt-3 md:mt-0 md:text-base">
                    {val.stallLocation}
                  </p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PickupStallPopup;
