import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  initiateErr: "",
  initiate: "",
  confirm: "",
  confirmErr: "",
};

export const Khalti = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        initiate: action.payload,
      };

    case ActionTypes.INITIATE_TRANSACTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        initiateErr: action.payload?.message,
      };

    case ActionTypes.CONFIRM_TRANSACTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        confirm: action.payload,
      };

    case ActionTypes.CONFIRM_TRANSACTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        confirmErr: action.payload.message,
      };

    case ActionTypes.REMOVE_KHALTI_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        initiateErr: "",
        initiate: "",
        confirm: "",
        confirmErr: "",
      };

    default:
      return state;
  }
};
