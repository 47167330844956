import React, { useState } from "react";
import { Button } from "../../../Component";
import { IoMdClose } from "react-icons/io";

const preOrderType = [
  {
    id: 1,
    name: "Any",
    value: "Any",
  },

  {
    id: 2,
    name: "All",
    value: "All",
  },

  {
    id: 3,
    name: "College",
    value: "College",
  },
];

const PreOrderPopup = ({ setShowPreOrderPopup }) => {
  const [orderType, setOrderType] = useState("");

  const handleSelectPreOrderType = (value) => {
    if (orderType.includes(value)) {
      setOrderType((pre) => [...pre.filter((val) => val !== value)]);
    } else {
      setOrderType((pre) => [...pre, value]);
    }
  };
  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-fit border overflow-y-auto bg-white shadow-md z-40 left-4 md:left-1/3 lg:left-1/4 xl:left-[30%] rounded-xl absolute top-24 md:top-[21rem] 2xl:top-64">
      {/* header */}
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-lg font-semibold">Pre Order Type</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setShowPreOrderPopup(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>

        <hr className="w-full mt-3" />
      </div>

      {/* preorder type */}
      <div className="mt-6 px-5 mb-5">
        <div className="w-full flex items-center gap-x-5 flex-wrap mt-7">
          {preOrderType.map((val) => {
            return (
              <Button
                key={val.id}
                value={val.name}
                handleClick={() => handleSelectPreOrderType(val.value)}
                className={`border rounded-xl w-fit py-2.5 px-5 ${
                  orderType.includes(val.value)
                    ? "border-primary text-primary bg-sky-100"
                    : ""
                }`}
              />
            );
          })}
        </div>
      </div>

      {/* buttons */}
      <div className="w-full h-20 border-t-2 bg-white sticky bottom-0 px-5 flex items-center justify-end gap-x-7">
        <p className="text-red-600 text-sm cursor-pointer">Clear Selected</p>
        <Button
          value="Apply Selected"
          className="bg-primary text-white text-sm py-2.5 px-5 rounded-xl"
        />
      </div>
    </div>
  );
};

export default PreOrderPopup;
