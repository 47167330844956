import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../../Component";
import Cookies from "js-cookie";
import { fetchAddReview, removeReviewData } from "../../../Redux/Review/action";
import { toast } from "react-toastify";

const Review = ({
  setShowReviewPopup,
  restaurantId,
  setRestaurantId,
  setOrderId,
}) => {
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const detail = useSelector(
    (state) => state.restaurant.detailPage,
    shallowEqual
  );

  const message = useSelector((state) => state.review.message, shallowEqual);
  const errMessage = useSelector((state) => state.review.errMsg, shallowEqual);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const initialValues = {
    reviewText: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      restaurantId: restaurantId,
      reviewText: values.reviewText,
      rating: rating,
    };
    dispatch(fetchAddReview(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setShowReviewPopup(false);
    setTimeout(() => {
      message && setRestaurantId();
      message && setOrderId();
      message && dispatch(removeReviewData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    setTimeout(() => {
      errMessage && dispatch(removeReviewData());
    }, 1000);
  }, [errMessage]);

  return (
    <div className="absolute top-7 h-[28rem] overflow-y-auto border rounded-2xl z-40 bg-white shadow-2xl py-3 w-11/12 md:w-10/12 2xl:w-3/4 left-4 md:left-[9%] 2xl:left-[12%] ">
      <div className="flex justify-between items-center w-full px-5">
        <h6 className="text-lg font-semibold">Leave a Review</h6>
        <div
          className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => {
            setShowReviewPopup(false);
            setRestaurantId();
            setOrderId();
          }}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>
      <hr className="w-full mt-3" />
      <div className=" w-full px-5 mt-5 md:mt-7">
        <div className="w-full h-32 md:h-44 overflow-hidden">
          <img
            src={detail.restaurantImageUrl}
            alt="img"
            className="w-full h-full object-fill"
          />
        </div>

        <h5 className="font-semibold text-lg md:text-xl text-center mt-2 py-2">
          {detail.restaurantName}
        </h5>
        <p className="text-secondary text-center text-sm md:text-base">
          {detail.restaurantLocation}
        </p>
      </div>
      <hr className="w-full mt-5" />

      <h6 className="text-base md:text-lg font-semibold text-center mt-5 py-2 px-5">
        How was your experience with us?
      </h6>
      <p className="text-secondary text-center mt-3 text-sm md:text-base">
        Select a star rating
      </p>

      <div className="text-center mt-4 flex gap-x-3 justify-center">
        {[1, 2, 3, 4, 5].map((num) => (
          <span onClick={() => handleStarClick(num)} key={num}>
            {num <= rating ? (
              <BsStarFill className="fill-yellow-500 text-3xl" />
            ) : (
              <BsStar className="text-3xl text-secondary hover:fill-yellow-500" />
            )}
          </span>
        ))}
      </div>

      <hr className="w-full mt-5" />
      <h6 className="text-base font-semibold text-center mt-5">
        Feel free to share about your experience with us
      </h6>

      <div className="px-5 mt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <FormikControl
                control="textarea"
                name="reviewText"
                placeholder="Feel free to tell us about your experience with us"
              />
              <hr className="w-full mt-5" />

              <div className="mt-4 px-5 flex items-center gap-x-5 w-full md:justify-end pb-3 flex-wrap md:flex-nowrap">
                <Button
                  value="Cancel"
                  className="border rounded-xl text-secondary text-sm py-3 px-5 w-full md:w-fit"
                  handleClick={() => {
                    setShowReviewPopup(false);
                    setRestaurantId();
                    setOrderId();
                  }}
                />
                <Button
                  value="Leave a Review"
                  className="rounded-xl bg-primary text-white text-sm py-3 px-5 w-full md:w-fit mt-5 md:mt-0"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Review;
