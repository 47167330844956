import React from "react";
import { TfiReceipt } from "react-icons/tfi";
import {
  Button,
  Load,
  OrderHistoryCard,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { useNavigate } from "react-router-dom";
import {
  fetchGetOrderHistoryCase,
  removeRestaurantData,
} from "../../Redux/Restaurant/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useState } from "react";

const CancelledOrders = ({ toggle }) => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.restaurant.isloading,
    shallowEqual
  );

  const orders = useSelector(
    (state) => state.restaurant.orderHistory,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.restaurant.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.restaurant.totalPage,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetOrderHistoryCase(page, "cancelled", token));
    return () => {
      dispatch(removeRestaurantData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full h-full">
        <Load />
      </div>
    );
  }
  return (
    <div className="w-full">
      {orders && orders.length ? (
        <div>
          {orders.map((val) => {
            return (
              <div key={val.customerOrderId} className="mt-7">
                <OrderHistoryCard data={val} toggle={toggle} />
              </div>
            );
          })}
          <div className="w-full mt-10">
            {totalPage > 5 ? (
              <Pagination
                totalData={totalData}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
          <TfiReceipt className="text-7xl text-gray-300" />
          <h6 className="font-semibold text-xl mt-8 py-2">
            No Order Cancelled Yet
          </h6>
          <p className="text-secondary mt-1">
            Your cancelled orders will be shown here.
          </p>
          <Button
            value="Find Restaurant to Order"
            handleClick={() => navigate("/popular-restaurant")}
            className="w-fit px-5 py-2.5 bg-primary text-white rounded-lg mt-10"
          />
        </div>
      )}
    </div>
  );
};

export default CancelledOrders;
