import KhaltiCheckout from "khalti-checkout-web";
import React from "react";
import { IoMdClose } from "react-icons/io";

const Khaltipopup = ({ khaltiConfig, price, setShowKhaltiPopup }) => {
  let checkout = new KhaltiCheckout(khaltiConfig);

  return (
    <div className="absolute top-40 w-11/12 md:w-2/3 lg:w-2/5 xl:w-1/3 left-[4%] md:left-[16%] lg:left-1/4 xl:left-1/3 rounded-xl border bg-white z-40 p-5 h-fit">
      <div className="w-full flex items-center justify-end">
        <div
          className="p-2 w-fit flex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => setShowKhaltiPopup(false)}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-5">
        <button
          className="border py-3 w-fit px-10 rounded-xl text-primary border-primary hover:bg-blue-100"
          onClick={() => checkout.show({ amount: (price * 100).toFixed(0) })}
        >
          Pay via khalti
        </button>
      </div>
    </div>
  );
};

export default Khaltipopup;
