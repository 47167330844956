import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Load } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import { useNavigate } from "react-router-dom";
import { fetchVerifyCoupon } from "../../Redux/Coupon/action";
import Cookies from "js-cookie";
import { fetchUseCoin } from "../../Redux/Checkout/action";

const OrderSummary = ({
  handleClickContinueToPayment,
  handleClickContinueToReview,
  toggle,
  payment,
  handleClickPlaceOrder,
  setCouponCode,
  couponCode,
  cartId,
  couponCodeApplied,
  coinApplied,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const orders = useSelector((state) => state.checkout.orders, shallowEqual);
  const loading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );

  const couponData = useSelector((state) => state.coupon.data, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const coinData = useSelector(
    (state) => state.checkout.coinData,
    shallowEqual
  );

  const handleClickApplyCouponCode = () => {
    const body = {
      couponCode: couponCode,
      cartId: cartId,
    };
    dispatch(fetchVerifyCoupon(body, token));
  };

  const handleClickApplyCoin = () => {
    const body = {
      cartId: cartId,
    };
    dispatch(fetchUseCoin(body, token));
  };

  if (loading) {
    return (
      <div>
        <Load />
      </div>
    );
  }

  return (
    <div className="w-full h-fit rounded-xl bg-white mt-10 md:mt-0">
      <div className="p-5">
        <h6 className="font-semibold text-xl">Order Summary</h6>
        {/* menus */}
        <div className="w-full">
          {orders?.cartItemDto?.map((val) => {
            return (
              <div
                key={val.id}
                className="mt-10 flex items-center gap-x-5 w-full"
              >
                <div className="w-16 h-16 overflow-hidden rounded-full">
                  <img
                    src={val.imageUrl}
                    alt="img"
                    height={0}
                    className="w-full h-full object-fill"
                  />
                </div>
                <div className="w-3/4">
                  <div className="w-full flex justify-between items-center">
                    <h6 className="font-medium w-3/4 truncate text-sm ">
                      {val.itemName}
                    </h6>
                    <h6 className="font-semibold">Rs. {val.cartItemTotal}</h6>
                  </div>
                  <div className="flex items-center gap-x-5 mt-2">
                    <p className="text-sm font-medium">
                      Rs. <span className="text-sm">{val.price}</span>
                    </p>
                    <p className="text-sm font-medium">
                      Qty:{" "}
                      <span className="text-base text-primary">
                        {val.quantity}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {(payment === "KHALTI" || payment === "STRIPE") &&
      toggle.delivery &&
      toggle.payment ? (
        <>
          <hr className="mt-2" />
          {coinApplied ? null : (
            <div className="text-sm mt-2 p-5">
              <label className="pb-2 text-sm ">Coupon Code</label>
              <div className="w-full flex items-center justify-between">
                <input
                  type="text"
                  placeholder="Add promo code"
                  className="border mt-2 border-gray-300 w-2/3 p-3 text-sm rounded-lg outline-gray-300"
                  onChange={(e) => setCouponCode(e.target.value)}
                  readOnly={couponCodeApplied ? true : false}
                />
                <Button
                  value="Apply Code"
                  handleClick={handleClickApplyCouponCode}
                  className="w-fit py-3 px-5 bg-primary text-white rounded-xl"
                />
              </div>
              <p className="text-primary text-xs mt-5">
                *Coupon code is not available for Cash on Delivery Option
              </p>
            </div>
          )}
          {profile.userCoin >= 100 && !couponCodeApplied ? (
            <div className="p-5">
              <Button
                value={`${coinApplied ? "Coin Used" : `Use Coins (100)`} `}
                handleClick={coinApplied ? "" : handleClickApplyCoin}
                className={`w-full py-3 rounded-xl ${
                  coinApplied
                    ? "bg-gray-100 text-primary font-medium"
                    : "bg-primary text-white"
                }`}
              />
            </div>
          ) : null}
        </>
      ) : null}

      {toggle.delivery && !toggle.payment && !toggle.review ? null : (
        <>
          <hr className="mt-2" />

          <div className="w-full mt-2 p-5">
            {couponCodeApplied ? (
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Coupon Code ({couponData?.couponCode})</p>
                <p>Rs. {couponData?.couponDiscount}</p>
              </div>
            ) : null}

            {coinApplied ? (
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Coin Discount </p>
                <p> {coinData?.loyaltyDiscount}</p>
              </div>
            ) : null}

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Subtotal</p>
              <div className="flex flex-col">
                <p
                  className={`${
                    couponCodeApplied || coinApplied ? "line-through" : ""
                  }`}
                >
                  Rs. {orders.paymentObject.cartSubTotal}
                </p>
                <p
                  className={`${
                    couponCodeApplied || coinApplied
                      ? "block font-semibold text-primary"
                      : "hidden"
                  }`}
                >
                  Rs.{" "}
                  {couponCodeApplied
                    ? couponData?.cartSubTotal
                    : coinApplied
                    ? coinData?.cartSubTotal
                    : null}
                </p>
              </div>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Service Charge</p>
              <p>
                Rs.{" "}
                {couponCodeApplied
                  ? couponData?.serviceCharge
                  : coinApplied
                  ? coinData?.serviceCharge
                  : orders.paymentObject.serviceCharge}
              </p>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Tax</p>
              <p>
                Rs.{" "}
                {couponCodeApplied
                  ? couponData?.taxableAmount
                  : coinApplied
                  ? coinData?.taxAmount
                  : orders.paymentObject.taxAmount}
              </p>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Delivery Charge</p>
              <p>
                Rs.{" "}
                {couponCodeApplied
                  ? couponData?.totalDeliveryCharge
                  : coinApplied
                  ? coinData?.totalDeliveryCharge
                  : orders.paymentObject.totalDeliveryCharge}
              </p>
            </div>

            <hr className="mt-5" />

            {/* grand total */}
            <div className="w-full mt-5 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg">
              <p>Grand Total</p>
              <p>
                Rs.{" "}
                {couponCodeApplied
                  ? couponData?.grandTotal
                  : coinApplied
                  ? coinData?.grandTotal
                  : orders.paymentObject.grandTotal}
              </p>
            </div>
            {toggle.delivery && toggle.payment && toggle.review ? (
              <p className="text-xs text-red-600 mt-2">
                *Payment Once done is Non-Refundable
              </p>
            ) : null}
          </div>
          <hr className="mt-2" />
        </>
      )}

      <div className="mt-2 flex items-center flex-col w-full p-5">
        {toggle.delivery && !toggle.payment && !toggle.review ? (
          <Button
            value="Continue to Payment"
            handleClick={() => {
              handleClickContinueToPayment();
              scrollToTop();
            }}
            className="w-full bg-primary rounded-xl text-white text-sm py-3"
          />
        ) : toggle.delivery && toggle.payment && !toggle.review ? (
          <Button
            value="Continue to Review"
            handleClick={() => {
              handleClickContinueToReview();
              scrollToTop();
            }}
            className="w-full bg-primary rounded-xl text-white text-sm py-3"
          />
        ) : (
          <Button
            value="Place Order"
            handleClick={handleClickPlaceOrder}
            className="w-full bg-primary rounded-xl text-white text-sm py-3"
          />
        )}

        <Button
          value="Back to Shopping"
          handleClick={() => navigate("/")}
          className="w-full bg-white rounded-xl hover:underline text-primary text-sm py-3 mt-5"
        />
      </div>
    </div>
  );
};

export default OrderSummary;
