import React, { useEffect } from "react";
import { BsHeart } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { schedule } from "../../Asset/Asset";
import { AiFillHeart } from "react-icons/ai";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchAddPreorderWishlist,
  fetchDeletePreorderWishlist,
} from "../../Redux/Wishlist/action";

const PreOrderCard = ({ data, wishlisted }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const handelClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddPreorderWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeletePreorderWishlist(id, token));
  };

  return (
    <div
      className="cursor-pointer hover:shadow-md rounded-xl transition-all duration-500 ease-in-out p-1 hover:p-3 hover:border"
      onClick={() =>
        navigate(`/preorder/${data.planId ? data.planId : data.id}`)
      }
    >
      <div className="w-full h-60 overflow-hidden rounded-xl relative">
        <img
          src={data.planImage}
          width={2000}
          height={0}
          alt="pre order img"
          className=" object-cover w-full h-full transition-all duration-500 ease-in-out hover:scale-125"
        />
        {wishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) =>
              handleClickRemove(e, data.planId ? data.planId : data.id)
            }
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : data.isWishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickRemove(e, data.planId)}
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handelClickAdd(e, data.planId)}
          >
            <BsHeart />
          </div>
        )}

        <p className="py-1 px-3 w-fit text-sm rounded-md font-medium text-primary bg-white absolute bottom-3 left-3">
          {data.preOrderType}
        </p>
      </div>
      <div className="mt-5">
        <h6 className="font-medium"> {data.planName}</h6>
        {data.restaurantLocation ? (
          <div className="flex items-center gap-x-2 w-full text-sm mt-2">
            <MdLocationOn className="text-lg text-primary" />
            <p className=" text-gray-400">{data.restaurantLocation}</p>
          </div>
        ) : null}

        <hr className="w-full mt-5" />
        <div className="flex items-center justify-between mt-5">
          <div>
            <div className="flex items-center gap-x-3">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p className="text-sm">Start Date</p>
            </div>
            <p className="text-xs xl:text-sm py-2 mt-2">
              {moment.utc(data.preOrderStartDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
          <div>
            <div className="flex items-center gap-x-3">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p className="text-sm">End Date</p>
            </div>
            <p className="text-xs xl:text-sm py-2 mt-2">
              {moment.utc(data.preOrderEndDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreOrderCard;
