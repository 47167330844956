import React, { useEffect } from "react";
import Header from "./Header";
import { AiOutlineStar } from "react-icons/ai";
import {
  Load,
  Pagination,
  ReviewCard,
  SinglePagnination,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchGetMyReviews,
  removeAccountData,
} from "../../Redux/Account/action";
import { useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";

const Reviews = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const loading = useSelector((state) => state.account.isloading, shallowEqual);
  const reviews = useSelector((state) => state.account.myReviews, shallowEqual);
  const totalData = useSelector(
    (state) => state.account.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.account.totalPage,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyReviews(page, token));
    return () => {
      dispatch(removeAccountData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return (
    <div className="w-full">
      <Header />
      {reviews && reviews.length ? (
        <div className="mt-10">
          {reviews.map((val) => {
            return (
              <div className="mt-7" key={val.id}>
                <ReviewCard data={val} />
              </div>
            );
          })}
          <div className="w-full mt-10">
            {totalPage > 5 ? (
              <Pagination
                totalData={totalData}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
          <AiOutlineStar className="text-7xl text-gray-300" />
          <h6 className="font-semibold text-xl mt-8 py-2">No Reviews Yet</h6>
          <p className="text-secondary mt-1">
            Your reviewed restaurnats will be shown here.
          </p>
        </div>
      )}
    </div>
  );
};

export default Reviews;
