import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const restaurantLoading = () => ({
  type: ActionTypes.RESTAURANT_LOADING,
});

export const removeRestaurantData = () => ({
  type: ActionTypes.REMOVE_RESTAURANT_DATA,
});

export const fetchGetPopularRestaurant =
  (page, location, lat, lng, keyword) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/customer/restaurant/popular?location=${location}&latitude=${lat}&longitude=${lng}&page=${page}&keyword=${keyword}`
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_POPULAR_RESTAURANT,
          payload: data,
        });
      });
  };

export const fetchGetPopularRestaurantUser =
  (page, location, lat, lng, keyword, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/customer/restaurant/user/popular?location=${location}&latitude=${lat}&longitude=${lng}&page=${page}&keyword=${keyword}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_POPULAR_RESTAURANT_USER,
          payload: data,
        });
      });
  };

export const fetchGetRestaurantDetailPage = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/customer/restaurant/web?id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DETAIL_PAGE,
        payload: data,
      });
    });
};

export const fetchGetRestaurantDetailPageUser =
  (id, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v1/api/customer/restaurant/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_DETAIL_PAGE_USER,
          payload: data,
        });
      });
  };

export const fetchGetRestaurantDetailPage2 = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/customer/restaurant/web?id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DETAIL_PAGE_2,
        payload: data,
      });
    });
};

export const fetchGetRestaurantMenu = (id) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v1/api/menu/list-active-category-items-web?restaurantId=${id}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_RESTAURANT_MENU,
        payload: data,
      });
    });
};

export const fetchGetItemById = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/customer/restaurant/item/web?itemId=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ITEM_BY_ID,
        payload: data,
      });
    });
};

export const fetchGetNearbyRestaurant =
  (page, location, lat, lng, keyword) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/customer/restaurant/nearby?location=${location}&latitude=${lat}&longitude=${lng}&page=${page}&keyword=${keyword}`
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_NEARBY_RESTAURANT,
          payload: data,
        });
      });
  };

export const fetchGetNearbyRestaurantUser =
  (page, location, lat, lng, keyword, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/customer/restaurant/user/nearby?location=${location}&latitude=${lat}&longitude=${lng}&page=${page}&keyword=${keyword}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_NEARBY_RESTAURANT_USER,
          payload: data,
        });
      });
  };

export const fetchGetOrderHistory = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/list-orders?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ORDER_HISTORY,
        payload: data,
      });
    });
};

export const fetchGetOrderHistoryCase =
  (page, key, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v1/api/user/order-filter-key/${key}?page=${page}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_HISTORY,
          payload: data,
        });
      });
  };

export const fetchGetOrderHistroyDetail = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/order-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_HISTORY_DETAIL,
        payload: data,
      });
    });
};
