import React, { useState } from "react";
import {
  Button,
  Load,
  Pagination,
  PreOrderHistoryCard,
  SinglePagnination,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { HiOutlineShoppingBag } from "react-icons/hi";
import Header from "./Header";
import { useEffect } from "react";
import {
  fetchGetMyPreOrder,
  removePreorderData,
} from "../../Redux/Preorder/action";
import { scrollToTop } from "../../Component/NavigateTop";

const MyPreOrder = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );
  const preOrders = useSelector(
    (state) => state.preOrder.myPreOrder,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.preOrder.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.preOrder.totalPage,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyPreOrder(page, token));
    return () => {
      dispatch(removePreorderData());
    };
  }, [page]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="w-full">
      <Header />
      <div className="mt-7 w-full">
        {preOrders && preOrders.length ? (
          <div>
            {preOrders.map((val, i) => {
              return (
                <div key={i} className="mt-7">
                  <PreOrderHistoryCard data={val} />
                </div>
              );
            })}
            <div className="w-full mt-10">
              {totalPage > 5 ? (
                <Pagination
                  totalData={totalData}
                  totalPage={totalPage}
                  page={page}
                  setPage={setPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
            <HiOutlineShoppingBag className="text-7xl text-gray-300" />
            <h6 className="font-semibold text-xl mt-8 py-2">
              No Pre Ordered Yet
            </h6>
            <p className="text-secondary mt-1">
              Your pre order will be shown here.
            </p>
            <Button
              value="Find Pre Order"
              handleClick={() => navigate("/preorder")}
              className="w-fit px-5 py-2.5 bg-primary text-white rounded-lg mt-10"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPreOrder;
