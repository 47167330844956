import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoMdClose, IoMdNotificationsOutline } from "react-icons/io";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const menu = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },

  {
    id: 2,
    name: "Pre Order",
    link: "/preorder",
  },

  {
    id: 3,
    name: "Campaign",
    link: "/campaign",
  },

  {
    id: 4,
    name: "Popular Restaurant",
    link: "/popular-restaurant",
  },

  {
    id: 5,
    name: "Nearby Restaurant",
    link: "/nearby-restaurant",
  },
];

const MobileMenu = ({
  mobileMenu,
  setMobileMenu,
  setShowCart,
  setShowNotification,
}) => {
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const handleClickLogout = () => {
    Cookies.remove("access_token");
    window.location.reload(false);
  };

  const handleClickCart = () => {
    if (!token) {
      navigate("/auth");
    } else {
      setMobileMenu(false);
      setShowCart(true);
      setShowNotification(false);
    }
  };

  const handleClickNotification = () => {
    if (!token) {
      navigate("/auth");
    } else {
      setMobileMenu(false);
      setShowNotification(true);
      setShowCart(false);
    }
  };

  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-2/3 sm:w-2/6 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        mobileMenu ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div
        className="p-2 mt-5 flex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
        onClick={() => setMobileMenu(false)}
      >
        <IoMdClose className="text-2xl" />
      </div>

      <div className="w-full mt-7 px-5">
        {menu.map((val) => {
          return (
            <div
              key={val.id}
              className="w-full flex flex-col gap-y-2 py-2 mt-2"
              onClick={() => setMobileMenu(false)}
            >
              <Link to={val.link}>
                <p className="text-lg">{val.name}</p>
              </Link>
            </div>
          );
        })}
      </div>

      <hr className="w-full mt-5" />

      <div className="mt-7 px-5 w-full flex flex-col gap-y-2">
        <div
          className="flex w-full items-center gap-x-3"
          onClick={handleClickNotification}
        >
          <IoMdNotificationsOutline className="text-2xl" />
          <p className="text-lg">Notification</p>
        </div>

        <div
          className="flex items-center w-full gap-x-3 mt-7"
          onClick={handleClickCart}
        >
          <AiOutlineShoppingCart className="text-2xl" />
          <p className="text-lg">Cart</p>
        </div>
      </div>

      <hr className="w-full mt-5" />
      {token ? (
        <div className="mt-7 w-full flex flex-col gap-y-2 px-5">
          <Button
            value="My Account"
            handleClick={() => {
              navigate("/me");
              setMobileMenu(false);
            }}
            className="border bg-primary text-white rounded-xl py-3 px-5 mt-6"
          />
          <Button
            value="Logout"
            handleClick={() => {
              handleClickLogout();
              setMobileMenu(false);
            }}
            className="border border-red-600 text-red-600 rounded-xl py-3 px-5 mt-6"
          />
        </div>
      ) : (
        <div className="mt-7 w-full flex flex-col gap-y-2 px-5">
          <Button
            value="Sign in"
            handleClick={() => {
              navigate("/auth");
              setMobileMenu(false);
            }}
            className="border rounded-xl text-primary py-3 px-5"
          />
          <Button
            value="Sign up"
            handleClick={() => {
              navigate("/auth/register");
              setMobileMenu(false);
            }}
            className="border bg-primary text-white rounded-xl py-3 px-5 mt-6"
          />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
