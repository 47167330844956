import React from "react";
import Avatar from "react-avatar";
import { dollar } from "../../Asset/Asset";
import { BiCategory, BiLogOut } from "react-icons/bi";
import MobileProfileMenu from "./MobileProfileMenu";
import { shallowEqual, useSelector } from "react-redux";
import Cookies from "js-cookie";

const profile = {
  first_name: "Bidhan",
  last_name: "Singh",
};

const ProfileHeader = ({ showMobileMenu, setShowMobileMenu }) => {
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const handleClickLogout = () => {
    Cookies.remove("access_token");
    window.location.reload(false);
  };

  return (
    <div className="mt-10 relative">
      <div className="flex items-center justify-between">
        <h5 className="font-semibold text-xl md:text-2xl">My Account</h5>
        <BiCategory
          className="block md:hidden text-3xl text-secondary"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
      </div>
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap mt-7 md:mt-5">
        <div className="mt-6 flex gap-x-6 items-center">
          <div className="hidden md:block">
            {profile && profile.imageUrl ? (
              <div className="w-20 h-20">
                <img
                  src={profile.imageUrl}
                  alt=""
                  className="w-full h-full rounded-full"
                />
              </div>
            ) : (
              <Avatar name={profile.firstName} size={90} round={true} />
            )}
          </div>

          <div className="block md:hidden">
            {profile && profile.imageUrl ? (
              <div className="w-14 h-14">
                <img
                  src={profile.imageUrl}
                  alt=""
                  className="w-full h-full rounded-full"
                />
              </div>
            ) : (
              <Avatar name={profile.firstName} size={70} round={true} />
            )}
          </div>
          <div>
            <h6 className="font-semibold text-xl">
              {profile.firstName} {profile.lastName}
            </h6>
            <div className="flex items-center gap-x-2 py-1">
              <p className="text-sm md:text-base">Total Points Earned: </p>
              <div className="flex items-center gap-x-2 text-xl font-medium">
                <img
                  src={dollar}
                  alt="dollar"
                  className="w-5 h-5 object-fill"
                />
                <p className="text-primary text-sm md:text-base">
                  {profile.userCoin} Coins
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-fit hidden  bg-red-700 py-3 px-6 lg:flex items-center gap-x-2 text-white rounded-xl cursor-pointer"
          onClick={handleClickLogout}
        >
          <BiLogOut className="text-xl" />
          <p>Logout </p>
        </div>

        <BiCategory
          className="hidden md:block lg:hidden text-5xl mt-7 md:mt-0 text-secondary"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
      </div>

      <MobileProfileMenu
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
    </div>
  );
};

export default ProfileHeader;
