import React from "react";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../../Component";

const StartDayPopup = ({ setShowStartDayPopup }) => {
  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-fit border overflow-y-auto bg-white shadow-md z-40 left-4 md:left-[20%] lg:left-1/4 xl:left-1/3 rounded-xl absolute top-24 md:top-[21rem] 2xl:top-64">
      {/* header */}
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-lg font-semibold">Start Date</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setShowStartDayPopup(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>

        <hr className="w-full mt-3" />
      </div>

      <div className="px-5 mb-6 w-full"></div>

      {/* buttons */}
      <div className="w-full h-20 border-t-2 bg-white sticky bottom-0 px-5 flex items-center justify-between">
        <div className="bg-blue-100 font-medium w-fit py-2.5 px-5 text-primary text-sm rounded-xl">
          <p>1 Selected</p>
        </div>
        <div className="flex items-center gap-x-6">
          <p className="text-red-600 text-sm cursor-pointer">Clear Selected</p>
          <Button
            value="Apply Selected"
            className="bg-primary text-white text-sm py-2.5 px-5 rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default StartDayPopup;
