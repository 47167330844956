import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetItemById,
  fetchGetRestaurantDetailPage,
  fetchGetRestaurantMenu,
} from "../../../Redux/Restaurant/action";
import { Button, Load } from "../../../Component";
import { IoMdClose } from "react-icons/io";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import {
  fetchAddtoCart,
  fetchGetMyCart,
  removeCartData,
  // removeCartData,
} from "../../../Redux/Cart/action";
import { fetchGetRestaurantCart } from "../../../Redux/Checkout/action";

const CartPopup = ({
  resId,
  itemId,
  setShowCartPopup,
  setShowCheckoutPopup,
}) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const item = useSelector((state) => state.restaurant.item, shallowEqual);
  const loading = useSelector(
    (state) => state.restaurant.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.cart.message, shallowEqual);
  const errMsg = useSelector((state) => state.cart.errMsg, shallowEqual);

  const handleIncreaseQuantity = () => {
    setQuantity((pre) => pre + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((pre) => pre - 1);
    }
  };

  const initialValues = {
    note: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      restaurantId: resId,
      items: [
        {
          itemId: itemId,
          quantity: quantity,
          price: item?.price,
          note: values.note,
        },
      ],
    };

    dispatch(fetchAddtoCart(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(fetchGetItemById(itemId));
  }, [itemId]);

  useEffect(() => {
    message && toast.success(message);
    message && setShowCartPopup(false);

    message && dispatch(fetchGetRestaurantDetailPage(resId));
    message && dispatch(fetchGetRestaurantMenu(resId));
    message && token && dispatch(fetchGetRestaurantCart(resId, token));
    message && setShowCheckoutPopup(true);
    setTimeout(() => {
      // message && dispatch(removeCartData());
      message && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && setShowCheckoutPopup(false);
    setTimeout(() => {
      // errMsg && dispatch(removeCartData());
      errMsg && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [errMsg]);

  if ((!itemId && !resId) || loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return (
    <div className="absolute top-7  md:px-5 pt-2 pb-5 h-[30rem] md:h-fit overflow-y-auto border rounded-2xl z-40 bg-white shadow-2xl w-full md:w-11/12 lg:w-10/12 xl:w-2/3 2xl:w-1/2  md:left-7 lg:left-[9%] xl:left-[16%] 2xl:left-[25%] ">
      <div className="w-full flex justify-end">
        <div
          className="p-2 hover:bg-gray-200 cursor-pointer rounded-md w-fit"
          onClick={() => {
            setShowCartPopup(false);
            // dispatch(removeCartData());
          }}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>

      <div className="w-full grid grid-cols-12 gap-x-8">
        <div className="col-span-12 md:col-span-5 h-60 md:h-full w-full rounded-xl overflow-hidden">
          <img
            src={item.imageUrl}
            alt="img"
            className="w-full h-full object-contain rounded-xl"
          />
        </div>

        <div className="col-start-1 md:col-start-6 col-end-13 h-full mt-5 md:mt-0 px-2">
          <h5 className="font-semibold text-lg">{item.itemName}</h5>
          <p className="text-sm text-secondary py-1">{item.description}</p>
          <h5 className="text-2xl font-semibold py-1">Rs. {item.price}</h5>

          <hr className="mt-3 w-full" />
          <div className="mt-3">
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className="text-sm">
                    <label className="pb-2 flex text-textPrimary text-sm items-center justify-between">
                      Special Instructions
                    </label>
                    <Field
                      as="textarea"
                      placeholder="Add a note"
                      id="note"
                      name="note"
                      className="border border-gray-300 w-full p-3 h-28 text-sm rounded-lg outline-gray-300"
                    />
                  </div>

                  <hr className="w-full mt-3" />

                  <div className="mt-5 flex items-center justify-between md:flex-row flex-col">
                    <div className="flex items-center gap-x-3">
                      <div
                        className="px-2 font-medium text-xl border border-primary rounded-md cursor-pointer text-primary"
                        onClick={handleDecreaseQuantity}
                      >
                        -
                      </div>
                      <p className="text-base font-semibold">{quantity}</p>
                      <div
                        className="px-2 font-medium text-xl border border-primary rounded-md cursor-pointer text-primary"
                        onClick={handleIncreaseQuantity}
                      >
                        +
                      </div>
                    </div>

                    <Button
                      value="Add to Cart"
                      type="submit"
                      className="w-full mt-3 md:mt-0 md:w-fit px-8 py-3 bg-primary text-white rounded-lg text-sm"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
