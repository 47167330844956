import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../Component";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const cuisine = [
  {
    id: 1,
    name: "Any",
  },

  {
    id: 2,
    name: "Burger",
  },

  {
    id: 3,
    name: "Biryani",
  },

  {
    id: 4,
    name: "Coffee",
  },

  {
    id: 5,
    name: "Bakery",
  },

  {
    id: 6,
    name: "Desserts",
  },

  {
    id: 7,
    name: "Chinese",
  },

  {
    id: 8,
    name: "Pizza",
  },

  {
    id: 9,
    name: "Continental",
  },

  {
    id: 10,
    name: "Roast Chicken",
  },
];

const preOrderType = [
  {
    id: 1,
    name: "Any",
    value: "Any",
  },

  {
    id: 2,
    name: "All",
    value: "All",
  },

  {
    id: 3,
    name: "College",
    value: "College",
  },
];

const Filter = ({ setShowFilter }) => {
  const [orderType, setOrderType] = useState("");
  const [value, setValue] = useState([0, 10000]);
  const [modifiedSecondArgument, setModifiedSecondArgument] = useState("");

  const handleSelectPreOrderType = (value) => {
    if (orderType.includes(value)) {
      setOrderType((pre) => [...pre.filter((val) => val !== value)]);
    } else {
      setOrderType((pre) => [...pre, value]);
    }
  };

  useEffect(() => {
    const secondArgument = value[1];
    secondArgument > 9000
      ? setModifiedSecondArgument("Any")
      : setModifiedSecondArgument(secondArgument);
  }, [value]);

  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-[33rem] border overflow-y-auto bg-white shadow-md z-40 left-4 md:left-[20%] lg:left-1/4 xl:left-1/3 rounded-xl absolute top-24 md:top-32">
      {/*header */}
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-lg font-semibold">Filter</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setShowFilter(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>

        <hr className="w-full mt-3" />
      </div>

      {/* cuisine */}
      <div className="mt-8 px-5">
        <h4 className="text-textPrimary text-lg font-semibold">Cuisine</h4>
        <hr className="w-8 border-2 border-primary mt-2" />
        <div className="w-full grid grid-cols-2 mt-5">
          {cuisine.map((val) => {
            return (
              <div className="flex items-center gap-x-3 py-2 mt-1" key={val.id}>
                <input type="checkbox" className="w-5 h-5 rounded-xl" />
                <p className="font-medium">{val.name}</p>
              </div>
            );
          })}
        </div>
      </div>
      <hr className="w-full mt-6" />

      {/* price range */}
      <div className="mt-6 px-5">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-textPrimary text-lg font-semibold">
              Price Range
            </h4>
            <hr className="w-8 border-2 border-primary mt-2" />
          </div>

          <h5 className="font-semibold text-xl">
            Nrs {[value[0], modifiedSecondArgument].join("-")}
          </h5>
        </div>
        <div className="mt-7">
          <RangeSlider
            value={value}
            onInput={setValue}
            min={0}
            max={10000}
            step={50}
          />
        </div>
      </div>

      <hr className="w-full mt-7" />

      {/* preorder type */}
      <div className="mt-6 px-5 mb-5">
        <h4 className="text-textPrimary text-lg font-semibold">
          Pre Order Type
        </h4>
        <hr className="w-8 border-2 border-primary mt-2" />
        <div className="w-full flex items-center gap-x-5 flex-wrap mt-7">
          {preOrderType.map((val) => {
            return (
              <Button
                key={val.id}
                value={val.name}
                handleClick={() => handleSelectPreOrderType(val.value)}
                className={`border rounded-xl w-fit py-2.5 px-5 ${
                  orderType.includes(val.value)
                    ? "border-primary text-primary bg-sky-100"
                    : ""
                }`}
              />
            );
          })}
        </div>
      </div>

      {/* buttons */}
      <div className="w-full z-50 h-20 border-t-2 bg-white sticky bottom-0 px-5 flex items-center justify-between">
        <p className="text-red-600">Clear Filter</p>
        <Button
          value="Apply Filter"
          className="bg-primary text-white py-2.5 px-5 rounded-xl"
        />
      </div>
    </div>
  );
};

export default Filter;
