import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "../../../Component";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../Component/NavigateTop";

const OrderSummary = ({
  quantity,
  toggle,
  selectDelivery,
  selectPickup,
  handleClickContinueToPayment,
  handleClickReview,
  handleClickPlaceOrder,
}) => {
  const navigate = useNavigate();

  const detail = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  const data = useSelector((state) => state.checkout.data, shallowEqual);

  return (
    <div className="w-full h-fit rounded-xl bg-white mt-10 md:mt-0">
      <div className="p-5">
        <h6 className="font-semibold text-xl">Order Summary</h6>
        <div className="mt-7 flex items-center gap-x-5 w-full">
          <div className="w-16 h-16 overflow-hidden rounded-full">
            <img
              src={detail.planImage}
              alt="img"
              height={0}
              className="w-full h-full object-fill"
            />
          </div>
          <div className="w-3/4">
            <div className="w-full flex justify-between items-center">
              <h6 className="font-medium w-3/4 truncate text-sm ">
                {detail.planName}
              </h6>
              <h6 className="font-semibold">
                Rs. {detail.planPrice * quantity}
              </h6>
            </div>
            <div className="flex items-center gap-x-5 mt-2">
              <p className="text-sm font-medium">
                Rs. <span className="text-sm">{detail.planPrice}</span>
              </p>
              <p className="text-sm font-medium">
                Qty: <span className="text-base text-primary">{quantity}</span>
              </p>
            </div>
          </div>
        </div>

        {/* charges */}
        {!toggle.payment && !toggle.review ? null : data ? (
          <>
            <hr className="mt-2" />

            <div className="w-full mt-2 p-5">
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Subtotal</p>
                <p>Rs. {detail.planPrice * quantity}</p>
              </div>

              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Delivery Charge</p>
                <p>Rs. {data?.deliveryCharge?.toFixed(2)}</p>
              </div>

              <hr className="mt-5" />

              {/* grand total */}
              <div className="w-full mt-5 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg">
                <p>Grand Total</p>
                <p>Rs. {data?.payableAmount?.toFixed(2)}</p>
              </div>
            </div>
          </>
        ) : null}

        <hr className="mt-2" />
        <div className="mt-2 flex items-center flex-col w-full p-5">
          {(selectDelivery || selectPickup) &&
          !toggle.payment &&
          !toggle.review ? (
            <Button
              value="Continue to Payment"
              handleClick={() => {
                handleClickContinueToPayment();
                scrollToTop();
              }}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          ) : (selectDelivery || selectPickup) &&
            toggle.payment &&
            !toggle.review ? (
            <Button
              value="Continue to Review"
              handleClick={() => {
                handleClickReview();
                scrollToTop();
              }}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          ) : (
            <Button
              value="Place Order"
              handleClick={() => {
                handleClickPlaceOrder();
                scrollToTop();
              }}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          )}

          <Button
            value="Back to Shopping"
            handleClick={() => navigate("/preorder")}
            className="w-full bg-white rounded-xl hover:underline text-primary text-sm py-3 mt-5"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
