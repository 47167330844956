import React, { useEffect } from "react";
import Header from "./Header";
import {
  Button,
  CampaignOrderCard,
  Load,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { FaMedal } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useState } from "react";
import {
  fetchGetMyCampaign,
  removeCampaignData,
} from "../../Redux/Campaign/action";

const MyCampaignOrders = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );
  const campaign = useSelector(
    (state) => state.campaign.myCampaign,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.campaign.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.campaign.totalPage,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetMyCampaign(page, token));

    setTimeout(() => {
      dispatch(fetchGetMyCampaign(page, token));
    }, 1000);
    return () => {
      dispatch(removeCampaignData());
    };
  }, [page]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return (
    <div className="w-full">
      <Header />

      <div className="mt-7 w-full">
        {campaign && campaign.length ? (
          <div>
            {campaign.map((val) => {
              return (
                <div key={val.id} className="mt-7">
                  <CampaignOrderCard data={val} />
                </div>
              );
            })}
            <div className="w-full mt-10">
              {totalPage > 5 ? (
                <Pagination
                  totalData={totalData}
                  totalPage={totalPage}
                  page={page}
                  setPage={setPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
            <FaMedal className="text-7xl text-gray-300" />
            <h6 className="font-semibold text-xl mt-8 py-2">
              No Campaign Ordered Yet
            </h6>
            <p className="text-secondary mt-1">
              Your campaign order will be shown here.
            </p>
            <Button
              value="Find Campaign"
              handleClick={() => navigate("/campaign")}
              className="w-fit px-5 py-2.5 bg-primary text-white rounded-lg mt-10"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCampaignOrders;
