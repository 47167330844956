import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, FormikControl } from "../../../Component";
import { Apple, Google, Logo } from "../../../Asset/Asset";
import { scrollToTop } from "../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGoogleLogin,
  fetchPhoneRegister,
  removeAuthData,
} from "../../../Redux/Auth/action";
import { toast } from "react-toastify";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

const Signup = () => {
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = localStorage.getItem("GCity");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const message = useSelector((state) => state.auth.message, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);
  const loginMessage = useSelector(
    (state) => state.auth.loginMsg,
    shallowEqual
  );

  const initialValues = {
    mobileNo: "",
    callingCode: "977",
    agreementFlag: true,
  };

  const validationSchema = Yup.object().shape({
    mobileNo: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long")
      .required("Required*"),
  });

  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchPhoneRegister(values));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
        .then(({ data }) => {
          const body = {
            firstName: data.given_name,
            lastName: data.family_name,
            emailId: data.email,
            mobileNumber: "",
            accessToken: data.id,
            userRegistrationType: "SOCIAL_GMAIL",
            location: location,
            longitude: lng,
            latitude: lat,
            isAllowedLocationAccess: true,
            dob: "",
            gender: "",
            referralCode: "",
          };
          dispatch(fetchGoogleLogin(body));
          scrollToTop();
        });
    },
  });

  useEffect(() => {
    loginMessage && toast.success(loginMessage);
    loginMessage && setSuccess(true);
    loginMessage && navigate("/");
    setTimeout(() => {
      loginMessage && dispatch(removeAuthData());
    }, 1000);
  }, [loginMessage]);

  useEffect(() => {
    message && toast.success(message);
    message && setSuccess(true);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setSuccess(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    success && navigate("/auth/register/verification");
  }, [success]);

  useEffect(() => {
    setSuccess(false);
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 xl:w-3/4 mx-auto h-full rounded-xl mt-14 bg-white grid grid-cols-12 lg:divide-x-2 pb-10 lg:pb-0">
        <div className="col-span-12 lg:col-span-6 xl:col-span-7 w-full h-full py-5 px-7">
          <img src={Logo} width="200" height="200" alt="logo" />
          <h6 className="font-semibold text-2xl mt-7">Welcome to GuruG!</h6>
          <p className="text-secondary text-sm py-2">Create your account</p>
          <div className="mt-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className="w-full">
                    <FormikControl
                      label="Phone"
                      control="input"
                      name="mobileNo"
                      type="number"
                      placeholder="Phone Number"
                      flag={true}
                    />
                  </div>

                  <p className="text-secondary text-sm mt-8">
                    By proceeding, you agree to our
                  </p>
                  <div className="flex items-center gap-x-3 text-sm mt-5">
                    <Link to="/terms-&-condition">
                      <p className="text-primary hover:underline cursor-pointer hover:font-medium">
                        Terms of Service
                      </p>
                    </Link>
                    <div className="border-r-2 h-4 text-textSecondary"></div>
                    <Link to="/privacy-policy">
                      <p className="text-primary hover:underline cursor-pointer hover:font-medium">
                        Privacy Policies
                      </p>
                    </Link>
                    {/* <div className="border-r-2 h-4 text-textSecondary"></div>
                    <p className="text-primary hover:underline hover:font-medium cursor-pointer">
                      Content Policies
                    </p> */}
                  </div>

                  <div className="mt-7 w-10/12 flex items-center justify-between">
                    <Button
                      type="submit"
                      value="Continue"
                      className="w-full rounded-xl bg-primary text-white py-3"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="col-start-1 lg:col-start-7 xl:col-start-8 col-end-13 w-11/12 mx-auto h-full py-5 px-3 md:px-8 mt-7 md:mt-10 lg:mt-0 border-t lg:border-t-0">
          <div className="flex items-center flex-wrap md:flex-nowrap justify-center w-full md:w-2/3 lg:w-10/12 mx-auto">
            <p className="text-sm text-secondary w-full text-center md:text-start">
              Already have account?
            </p>
            <Button
              value="Signin"
              handleClick={() => navigate("/auth")}
              className="border rounded-full mt-5 md:mt-0 text-primary py-2 w-fit px-10 hover:bg-primary hover:text-white transition-all ease-in-out duration-300"
            />
          </div>

          <div className="w-full md:w-2/3 mx-auto lg:w-full h-full mt-14 md:mt-20 lg:mt-32">
            <div className=" flex justify-center gap-x-4 md:gap-x-7 xl:gap-x-5 2xl:gap-x-7 items-center w-full">
              <hr className="w-full xl:w-1/2 2xl:w-full text-textSecondary border" />
              <p className="w-full text-xs md:text-sm">Or Sign in with</p>
              <hr className="w-full xl:w-1/2 wxl:w-full text-textSecondary border" />
            </div>
            <div
              className="w-full rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-10 border flex justify-between items-center transition duration-500 ease-in-out hover:border-primary hover:text-primary"
              onClick={() => googleLogin()}
            >
              <img
                src={Google}
                alt="google"
                width={25}
                height={25}
                className="object-fill"
              />
              <p className="text-center">Continue with Google</p>
            </div>
            {/* <div className="w-full rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-5 border flex justify-between items-center transition duration-500 ease-in-out hover:border-primary hover:text-primary">
              <img
                src={Apple}
                alt="apple"
                width={25}
                height={25}
                className="object-fill"
              />
              <p className="text-center">Continue with Apple</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
