import moment from "moment";
import React from "react";
import { Button } from "../../../../Component";

const ScheduleTime = ({ setScheduleDate, scheduleDate, setShowSchedule }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const tomorrowFormatted = tomorrow.toISOString().substr(0, 16);

  return (
    <div className="absolute top-40 w-11/12 md:w-2/3 lg:w-2/5 xl:w-1/3 left-[4%] md:left-[16%] lg:left-1/4 xl:left-1/3 rounded-xl border bg-white z-40 p-5 h-fit">
      <div>
        <label className="font-medium pb-2 text-xl">
          Schedule your date and time
        </label>
        <hr className="text-primary w-20 border-2 mt-2 border-primary" />
        <div className="flex items-center gap-x-3 w-full mt-7">
          <div className="w-full">
            <input
              className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
              type="datetime-local"
              value={moment
                .utc(scheduleDate)
                .local()
                .format("YYYY-MM-DDTHH:mm")}
              min={tomorrowFormatted}
              onChange={(e) =>
                setScheduleDate(
                  moment(e.target.value).utc().format("YYYY-MM-DDTHH:mm:ss")
                )
              }
            />
          </div>
        </div>
      </div>

      <Button
        value="Done"
        handleClick={() => setShowSchedule(false)}
        className="bg-primary w-full py-3 rounded-xl text-white mt-5"
      />
    </div>
  );
};

export default ScheduleTime;
