import React from "react";
import { Button, Search } from "../../../Component";
import { IoMdClose } from "react-icons/io";

const cuisine = [
  {
    id: 1,
    name: "Any",
  },

  {
    id: 2,
    name: "Burger",
  },

  {
    id: 3,
    name: "Biryani",
  },

  {
    id: 4,
    name: "Coffee",
  },

  {
    id: 5,
    name: "Bakery",
  },

  {
    id: 6,
    name: "Desserts",
  },

  {
    id: 7,
    name: "Chinese",
  },

  {
    id: 8,
    name: "Pizza",
  },

  {
    id: 9,
    name: "Continental",
  },

  {
    id: 10,
    name: "Roast Chicken",
  },
];

const CuisinePopup = ({ setShowCuisinePopup }) => {
  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-fit border overflow-y-auto bg-white shadow-md z-40 left-4 md:left-[16%] lg:left-[14%] xl:left-[20%] 2xl:left-1/3 rounded-xl absolute top-24 md:top-[21rem] 2xl:top-64">
      {/* header */}
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-lg font-semibold">Cuisine</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setShowCuisinePopup(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>

        <hr className="w-full mt-3" />
        <div className="w-full px-3 mt-5">
          <Search placeholder="Search for cuisine here" />
        </div>
      </div>

      {/* cuisine */}
      <div className="mt-5 px-5 mb-6">
        <div className="w-full grid grid-cols-2 mt-5">
          {cuisine.map((val) => {
            return (
              <div className="flex items-center gap-x-3 py-2 mt-1" key={val.id}>
                <input type="checkbox" className="w-5 h-5 rounded-xl" />
                <p className="font-medium">{val.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* buttons */}
      <div className="w-full h-20 border-t-2 bg-white sticky bottom-0 px-5 flex items-center justify-between">
        <div className="bg-blue-100 font-medium w-fit py-2.5 px-5 text-primary text-sm rounded-xl">
          <p>1 Selected</p>
        </div>
        <div className="flex items-center gap-x-6">
          <p className="text-red-600 text-sm cursor-pointer">Clear Selected</p>
          <Button
            value="Apply Selected"
            className="bg-primary text-white text-sm py-2.5 px-5 rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default CuisinePopup;
