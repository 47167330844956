import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  popular: "",
  nearby: "",
  orderHistory: [],
  menu: [],
  item: {},
  detailPage: "",
  detaulPages: "",
  totalData: "",
  totalPage: "",
};

export const Restaurant = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_POPULAR_RESTAURANT:
      return {
        ...state,
        isloading: false,
        success: true,
        popular: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_POPULAR_RESTAURANT_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        popular: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_NEARBY_RESTAURANT:
      return {
        ...state,
        isloading: false,
        success: true,
        nearby: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_NEARBY_RESTAURANT_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        nearby: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_DETAIL_PAGE:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_DETAIL_PAGE_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_DETAIL_PAGE_2:
      return {
        ...state,
        isloading: false,
        success: true,
        detaulPages: action.payload,
      };

    case ActionTypes.GET_RESTAURANT_MENU:
      return {
        ...state,
        isloading: false,
        success: true,
        menu: action.payload.data,
      };

    case ActionTypes.GET_ITEM_BY_ID:
      return {
        ...state,
        isloading: false,
        success: true,
        item: action.payload,
      };

    case ActionTypes.GET_ORDER_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        orderHistory: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        orderHistory: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_HISTORY_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.REMOVE_RESTAURANT_DATA:
      return {
        isloading: true,
        success: false,
        popular: "",
        nearby: "",
        orderHistory: [],
        detailPage: "",
        detaulPages: "",
        totalData: "",
        menu: [],
        item: {},
        totalPage: "",
      };

    default:
      return state;
  }
};
