import React from "react";
import { BiMessageRoundedEdit } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";

const GetinTouch = () => {
  return (
    <div>
      <h6 className="text-2xl font-semibold text-center">
        Want to get in touch
      </h6>
      <div className="grid md:grid-cols-3 w-full mt-10">
        <div className="flex flex-col items-center justify-center">
          <HiOutlineMail className="text-xl text-secondary" />
          <p className="mt-2">Email</p>
          <a
            href="mailto:contact@gurug.com"
            className="text-primary mt-2 cursor-pointer hover:underline"
          >
            Click Here
          </a>
          <p className="text-sm mt-2">to send us an email</p>
        </div>

        <a className="flex flex-col items-center justify-center mt-10 md:mt-0">
          <BsTelephone className="text-xl text-secondary" />
          <p className="mt-2">Phone</p>
          <p className="mt-2 cursor-pointer">014521837, 9801123002</p>
        </a>

        <div className="flex flex-col items-center justify-center mt-10 md:mt-0">
          <BiMessageRoundedEdit className="text-xl text-secondary" />
          <p className="mt-2">FAQs</p>
          <p className="mt-2 cursor-pointer text-center">
            Find the answer you need in our
          </p>
          <Link to="/faq">
            <p className="text-primary mt-2 cursor-pointer hover:underline">
              FAQ's
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetinTouch;
