import React from "react";
import { MdLocationOn, MdArrowDropDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { Logo, HomeImg } from "../../Asset/Asset";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Banner = ({ setShowLocation }) => {
  const [location, setLocation] = useState(localStorage.getItem("GCity"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  // on press enter or comma
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && keyword !== "") {
      e.preventDefault();

      navigate(`/popular-restaurant?key=${keyword}`);
    } else if (e.key === "Enter" && keyword === "") {
      navigate(`/popular-restaurant`);
    }
  };

  const handleSearchRestaurant = () => {
    if (keyword !== "") {
      navigate(`/popular-restaurant?key=${keyword}`);
    } else if (keyword === "") {
      navigate(`/popular-restaurant`);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setStreet(localStorage.getItem("GStreet"));
      setLocation(localStorage.getItem("GCity"));
    }, 1000);
  });

  return (
    <div className="w-full h-full bg-white">
      <div className="w-11/12 mx-auto grid grid-cols-12 gap-x-5 py-12">
        <div className="w-full col-span-12 md:col-span-6 h-full flex justify-center flex-col px-4 md:px-7">
          <img src={Logo} width={200} height={200} alt="logo" />
          <h3 className="font-semibold text-2xl lg:text-3xl mt-3 py-3">
            Discover the best food & drinks
          </h3>
          <div className="w-full border bg-white rounded-xl py-2 mt-7 px-3 hidden lg:flex items-center gap-x-3">
            <div
              className="w-60 border-r h-full py-1 px-3 flex items-center justify-end cursor-pointer"
              onClick={() => setShowLocation(true)}
            >
              <div className="flex items-center gap-x-3 w-full">
                <MdLocationOn className="text-2xl text-primary" />
                <p className="text-sm truncate text-gray-400 w-3/4">
                  {street ? street : location ? location : "Location"}
                </p>
              </div>
              <MdArrowDropDown className="text-3xl text-gray-500" />
            </div>

            <div className="w-full py-1 px-3 flex items-center gap-x-3">
              <input
                type="text"
                className="w-full py-1 outline-none border-none bg-white text-sm text-secondary"
                placeholder="Search for popular restaurant"
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <CiSearch
                className="text-3xl text-gray-400 cursor-pointer hover:text-primary"
                onClick={handleSearchRestaurant}
              />
            </div>
          </div>
        </div>
        <div className="w-full hidden md:block col-start-8 col-end-13 h-full">
          <img src={HomeImg} width={1000} height={1000} alt="banner" />
        </div>
      </div>

      <div className="w-11/12 mx-auto block lg:hidden">
        <div className="w-full md:w-2/3">
          <div className="w-full border bg-white rounded-xl py-2 mt-0 px-1 flex lg:hidden items-center gap-x-2">
            <div
              className="w-32 border-r h-full py-1 px-1 flex items-center justify-end cursor-pointer"
              onClick={() => setShowLocation(true)}
            >
              <div className="flex items-center gap-x-2 w-full">
                <MdLocationOn className="text-xl text-primary" />
                <p className="text-sm truncate text-gray-400 w-2/3">
                  {street ? street : location ? location : "Location"}
                </p>
              </div>
              <MdArrowDropDown className="text-2xl text-gray-500" />
            </div>

            <div className="w-full py-1 px-1 flex items-center gap-x-2">
              <input
                type="text"
                className="w-full text-sm py-1 outline-none border-none bg-white text-secondary"
                placeholder="Search for popular restaurant"
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <CiSearch
                className="text-2xl text-gray-400 hover:text-primary"
                onClick={handleSearchRestaurant}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
