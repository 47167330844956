import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { pizza } from "../../Asset/Asset";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Pagination, SinglePagnination } from "../../Component";
import {
  fetchGetReviewByRestaurant,
  removeReviewData,
} from "../../Redux/Review/action";
import Cookies from "js-cookie";
import { useEffect } from "react";

const reviews = [
  {
    id: 1,
    img: pizza,
    name: "Anonymous",
    time: "2 days ago",
    description:
      "Great restaurant! The atmosphere is welcoming, the service is prompt and friendly, and the food is delicious. Highly recommend this place for a memorable dine-in experience.",
    rating: "4.8",
    images: [
      {
        id: 1,
        img: pizza,
      },
      {
        id: 2,
        img: pizza,
      },
      {
        id: 3,
        img: pizza,
      },
      {
        id: 4,
        img: pizza,
      },
    ],
  },

  {
    id: 2,
    img: pizza,
    name: "Anonymous",
    time: "2 days ago",
    description:
      "Great restaurant! The atmosphere is welcoming, the service is prompt and friendly, and the food is delicious. Highly recommend this place for a memorable dine-in experience.",
    rating: "3.0",
    images: [],
  },

  {
    id: 3,
    img: pizza,
    name: "Anonymous",
    time: "2 days ago",
    description:
      "Great restaurant! The atmosphere is welcoming, the service is prompt and friendly, and the food is delicious. Highly recommend this place for a memorable dine-in experience.",
    rating: "1.0",
    images: [],
  },
];

const sorts = [
  {
    id: 1,
    name: "Most Recent",
    value: "Most Recent",
  },

  {
    id: 2,
    name: "Oldest Ones",
    value: "Oldest Ones",
  },

  {
    id: 3,
    name: "Highest Rated",
    value: "Highest Rated",
  },

  {
    id: 4,
    name: "Lowest Rated",
    value: "Lowest Rated",
  },
];

const ToggleReview = ({ id }) => {
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [sortValue, setSortValue] = useState("Most Recent");

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const review = useSelector((state) => state.review.review, shallowEqual);

  const totalData = useSelector(
    (state) => state.review.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.review.totalPage,
    shallowEqual
  );

  const handleChangeOptions = (e, value) => {
    const { checked } = e.target;
    if (checked) {
      setSortValue(value);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2 + 0.5,
    slidesToScroll: 1,
  };

  useEffect(() => {
    dispatch(fetchGetReviewByRestaurant(page, id));
    return () => {
      dispatch(removeReviewData());
    };
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="w-3/4">
          <h6 className="text-lg font-semibold">Our Reviews</h6>
          <hr className="w-14 border-2 border-primary mt-2" />
        </div>
        {/* sorting */}
        {/* <div className="w-full h-full relative ">
          <div className="flex items-center justify-end gap-x-2">
            <TbArrowsSort />
            <p className="text-sm font-medium">Sort by:</p>
          </div>
          <div
            className="flex items-center gap-x-2 py-2 justify-end cursor-pointer"
            onClick={() => setShow(!show)}
          >
            <h6 className="capitalize text-primary font-medium">{sortValue}</h6>
            <IoIosArrowDown className="text-secondary" />
          </div>
          {show && (
            <div className="w-fit absolute px-5 right-0 border z-40 py-2 bg-white rounded-md top-16 shadow-md">
              {sorts.map((val) => {
                return (
                  <div
                    key={val.id}
                    className="flex items-center gap-x-3 py-1.5 cursor-pointer hover:text-secondary"
                    onClick={() => {
                      setSortValue(val.name);
                      setShow(false);
                    }}
                  >
                    <input
                      type="radio"
                      name="radio"
                      className="bg-primary w-3.5 h-3.5"
                      checked={sortValue.includes(val.name) ? true : false}
                      onChange={(e) => handleChangeOptions(e, val.name)}
                    />
                    <p
                      className={`${
                        sortValue.includes(val.name) ? "font-medium" : ""
                      } `}
                    >
                      {val.name}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div> */}
      </div>

      <div className="w-full md:w-11/12 lg:w-3/4 mt-7">
        {review && review.length ? (
          review.map((val) => {
            return (
              <div className="mt-5 py-2" key={val.id}>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-x-5">
                    <div className="w-16 h-16 overflow-hidden rounded-full">
                      <img
                        src={`https://restaurant-dev.s3-ap-south-1.amazonaws.com/${val.restaurantimage}`}
                        width={2000}
                        alt="img"
                        height={0}
                        className="w-full h-full object-fill"
                      />{" "}
                    </div>
                    <div className=" flex flex-col">
                      <h6 className="font-medium">{val.fullName}</h6>
                      <div className="mt-1 flex items-center gap-x-4">
                        <p className="text-sm text-secondary">1 Review</p>
                        <p className="text-sm text-secondary">
                          {moment.utc(val.addedDate).local().fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      val.rating < 2
                        ? "bg-red-700"
                        : val.rating < 4
                        ? "bg-orange-700"
                        : " bg-green-700"
                    } text-sm py-1 px-2 text-white rounded-md w-fit flex items-center gap-x-1`}
                  >
                    <AiFillStar className="fill-white" />
                    <p>{val.rating}</p>
                  </div>
                </div>

                {/* decription */}
                <p className="text-sm px-3 text-secondary mt-5 py-2">
                  {val.reviewText}
                </p>
                {/* images */}
                {/* <div className="mt-5">
                <Slider {...settings}>
                  {val.images.map((itm) => {
                    return (
                      <div
                        className="rounded-xl px-3 h-24 md:h-32 lg:h-40 mx-auto overflow-hidden outline-none"
                        key={itm.id}
                      >
                        <img
                          src={itm.img}
                          alt="img"
                          height={0}
                          className=" object-fill w-full rounded-xl h-full"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div> */}

                {/* <div className="mt-5 flex items-center gap-x-7 flex-wrap">
                <div className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3">
                  <RiShareCircleFill className="text-primary text-lg" />
                  <p>Helpful </p>
                </div>

                <div className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3">
                  <RiShareCircleFill className="text-primary text-lg" />
                  <p>Comment </p>
                </div>

                <div className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0">
                  <RiShareCircleFill className="text-primary text-lg" />
                  <p>Share </p>
                </div>
              </div> */}
                <hr className="mt-5 w-full" />
              </div>
            );
          })
        ) : (
          <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
            <AiOutlineStar className="text-7xl text-gray-300" />
            <h6 className="font-semibold text-xl mt-8 py-2">No Reviews Yet</h6>
            <p className="text-secondary mt-1">
              All the review for this restaurant will be shown here.
            </p>
          </div>
        )}
      </div>

      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
};

export default ToggleReview;
