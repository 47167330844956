import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const cartLoading = () => ({
  type: ActionTypes.CART_LOADING,
});

export const removeCartData = () => ({
  type: ActionTypes.REMOVE_CART_DATA,
});

export const fetchAddtoCart = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/cart/add`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TO_CART_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_TO_CART_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchGetMyCart = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/cart/get-active`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_CART,
        payload: data,
      });
    });
};

export const fetchUpdateCartItem = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/cart/update-cart-item`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_CART_ITEM_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_CART_ITEM_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchDeleteRestaurantFromCart =
  (id, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v1/api/user/cart/clear-cart/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.DELETE_RESTAURANT_FROM_CART_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.DELETE_RESTAURANT_FROM_CART_FAIL,
          payload: e.response.data,
        });
      });
  };

export const fetchDeleteItemFromCart = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/cart/clear-cart-items/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_ITEM_FROM_CART_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_ITEM_FROM_CART_FAIL,
        payload: e.response.data,
      });
    });
};
