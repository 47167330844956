import moment from "moment";
import React from "react";
import { AiFillStar } from "react-icons/ai";
// import Slider from "react-slick";

const star = [
  {
    id: 1,
    icon: AiFillStar,
  },
  {
    id: 2,
    icon: AiFillStar,
  },
  {
    id: 3,
    icon: AiFillStar,
  },
  {
    id: 4,
    icon: AiFillStar,
  },
  {
    id: 5,
    icon: AiFillStar,
  },
];

const ReviewCard = ({ data }) => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 3.5,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },

  //     {
  //       breakpoint: 500,
  //       settings: {
  //         slidesToShow: 2.5,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="border w-full rounded-xl gap-x-5 p-5 hover:shadow-md">
      <div className="flex flex-wrap md:flex-nowrap items-center gap-x-5 w-full">
        <div className="w-full h-44 sm:h-60 md:w-60 md:h-40 overflow-hidden">
          <img
            src={`https://restaurant-dev.s3-ap-south-1.amazonaws.com/${data.restaurantimage}`}
            alt="img"
            className="w-full h-full rounded-xl object-contain"
          />
        </div>
        <div className="w-full mt-5 md:mt-0">
          <h5 className="text-lg font-semibold">{data.restaurantName}</h5>
          <div className="mt-2 flex items-center gap-x-3">
            <div className="flex items-center gap-x-2">
              {star.map((val, i) => {
                return (
                  <div className="" key={val.id}>
                    {" "}
                    <val.icon
                      className={`${
                        i < data.rating ? "text-yellow-500" : "text-secondary"
                      } text-lg`}
                    />{" "}
                  </div>
                );
              })}
            </div>
            {/* <p className="text-sm">{data.status}</p> */}
          </div>

          <p className="text-sm text-secondary mt-2 py-2">
            Reviewed on{" "}
            {moment.utc(data.addedDate).local().format("DD-MM-YYYY")}
          </p>
        </div>
      </div>

      <hr className="w-full mt-6" />

      {/* description */}
      <p className="text-secondary mt-7 text-sm leading-7">{data.reviewText}</p>

      {/* images */}
      {/* <div className="mt-7">
        {data.images && data.images.length > 3 ? (
          <Slider {...settings}>
            {data.images.map((itm) => {
              return (
                <div
                  className="rounded-xl px-2 h-28 md:h-36 2xl:h-40 mx-auto overflow-hidden outline-none"
                  key={itm.id}
                >
                  <img
                    src={itm.img}
                    alt="img"
                    width={2000}
                    height={0}
                    className=" object-fill w-full rounded-xl h-full"
                  />
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="w-full grid grid-cols-3 gap-x-3">
            {data.images.map((itm) => {
              return (
                <div
                  className="rounded-xl px-2 h-40 mx-auto overflow-hidden outline-none"
                  key={itm.id}
                >
                  <img
                    src={itm.img}
                    alt="img"
                    width={2000}
                    height={0}
                    className=" object-fill w-full rounded-xl h-full"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ReviewCard;
